export default function BackIcon() {
  return (
    <svg
      className="min-w-[24px]"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_262_1421)">
        <path
          d="M19.9907 11.5H7.82072L13.4107 5.91L11.9907 4.5L3.99072 12.5L11.9907 20.5L13.4007 19.09L7.82072 13.5H19.9907V11.5Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_262_1421">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(-0.00927734 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
