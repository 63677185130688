import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import FocusableChild from "./FocusableChild";
import { useSearchParams } from "react-router-dom";

const LocationInput = ({
	autoFocus = false,
	placeHolder = "Location",
	desc = "Where are you going?",
	className = "nc-flex-1.5",
	divHideVerticalLineClass = "left-10 -right-0.5",
	ignoredDirection,
	windowsOnTop = false,
}) => {
	const containerRef = useRef(null);
	const inputRef = useRef(null);
	const [query] = useSearchParams();
	const [value, setValue] = useState(() => {
		return query.get("location") || "";
	});
	const [showPopover, setShowPopover] = useState(autoFocus);
	const [inputFocused, setInputFocused] = useState(false);

	useEffect(() => {
		setInputFocused(false);
	}, [value]);

	useEffect(() => {
		setShowPopover(autoFocus);
	}, [autoFocus]);

	useEffect(() => {
		if (eventClickOutsideDiv) {
			document.removeEventListener("click", eventClickOutsideDiv);
		}
		if (showPopover) {
			document.addEventListener("click", eventClickOutsideDiv);
		}
		return () => {
			document.removeEventListener("click", eventClickOutsideDiv);
		};
	}, [showPopover]);

	useEffect(() => {
		if (showPopover && inputRef.current) {
			inputRef.current.focus();
		}
	}, [showPopover]);

	const eventClickOutsideDiv = (event) => {
		if (!containerRef.current) return;
		if (!showPopover || containerRef.current.contains(event.target)) {
			return;
		}
		setShowPopover(false);
	};

	const handleSelectLocation = (item) => {
		setValue(item);
		setShowPopover(false);
	};

	const renderRecentSearches = () => (
		<>
			<h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-[#1F2937] dark:text-neutral-100">
				Recent searches
			</h3>
			<div className="mt-2">
				{[
					"Hamptons, Suffolk County, NY",
					"Las Vegas, NV, United States",
					"Ueno, Taito, Tokyo",
					"Ikebukuro, Toshima, Tokyo",
				].map((item) => (
					<span
						onClick={() => handleSelectLocation(item)}
						key={item}
						className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
					>
						<span className="block text-neutral-400">
							<ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
						</span>
						<span className=" block font-medium text-neutral-700 dark:text-neutral-200">
							{item}
						</span>
					</span>
				))}
			</div>
		</>
	);

	const renderSearchValue = () => (
		<>
			{[
				"Ha Noi, Viet Nam",
				"San Diego, CA",
				"Humboldt Park, Chicago, IL",
				"Bangor, Northern Ireland",
			].map((item) => (
				<span
					onClick={() => handleSelectLocation(item)}
					key={item}
					className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
				>
					<span className="block text-neutral-400">
						<ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
					</span>
					<span className="block font-medium text-neutral-700 dark:text-neutral-200">
						{item}
					</span>
				</span>
			))}
		</>
	);

	return (
		<FocusableChild
			focusKeyParam={"engine-location"}
			className={`relative flex ${className} rounded-l-full`}
			ref={containerRef}
			outline={true}
			onFocus={() => setInputFocused(true)}
			onArrowPress={(e) => {
				setInputFocused(false);
				if (ignoredDirection) {
					if (e === ignoredDirection) {
						return false;
					}
				}
				return true;
			}}
		>
			<div
				onClick={() => setShowPopover(true)}
				onMouseLeave={() => setShowPopover(false)}
				className={`ml-4 flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
					showPopover ? "nc-hero-field-focused" : ""
				}`}
			>
				<div className="text-neutral-300 dark:text-neutral-400">
					<MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
				</div>
				<div className="flex-grow">
					<input
						name="location"
						className={`${
							value || value.length || inputFocused ? "" : "hidden"
						} block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-[#1F2937] dark:placeholder-neutral-200 truncate`}
						placeholder={placeHolder}
						value={value}
						autoFocus={showPopover}
						onChange={(e) => setValue(e.currentTarget.value)}
						ref={inputRef}
					/>
					<span
						className={`${
							value || value.length || inputFocused ? "hidden" : ""
						} block w-full xl:text-lg font-semibold text-[#1F2937] dark:text-neutral-200 truncate`}
					>
						{value.length ? value : placeHolder}
					</span>
					<span className="block mt-0.5 text-neutral-400 font-light">
						<span className="line-clamp-1">{value ? placeHolder : desc}</span>
					</span>
					{value && showPopover && (
						<ClearDataButton onClick={() => setValue("")} />
					)}
				</div>
			</div>

			{showPopover && (
				<div
					className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-[#1F2937] dark:bg-[#1F2937] ${divHideVerticalLineClass}`}
				></div>
			)}

			{showPopover && (
				<div
					className={`absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-[#1F2937] dark:bg-[#1F2937] py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto ${windowsOnTop ? "bottom-full mb-3" : "top-full mt-3"}`}
				>
					{value ? renderSearchValue() : renderRecentSearches()}
				</div>
			)}
		</FocusableChild>
	);
};

export default LocationInput;
