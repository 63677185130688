const LockIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.5234V14.5234"
        stroke="#BDBDBD"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M4.26781 18.8681C4.49269 20.5384 5.87613 21.8469 7.55966 21.9243C8.97627 21.9894 10.4153 22.0234 12 22.0234C13.5847 22.0234 15.0237 21.9894 16.4403 21.9243C18.1239 21.8469 19.5073 20.5384 19.7322 18.8681C19.879 17.7781 20 16.661 20 15.5234C20 14.3858 19.879 13.2687 19.7322 12.1787C19.5073 10.5084 18.1239 9.19993 16.4403 9.12253C15.0237 9.05741 13.5847 9.02344 12 9.02344C10.4153 9.02344 8.97627 9.05741 7.55966 9.12253C5.87613 9.19993 4.49269 10.5084 4.26781 12.1787C4.12104 13.2687 4 14.3858 4 15.5234C4 16.661 4.12104 17.7781 4.26781 18.8681Z"
        stroke="#BDBDBD"
        stroke-width="1.5"
      />
      <path
        d="M7.5 9.02344V6.52344C7.5 4.03816 9.51472 2.02344 12 2.02344C14.4853 2.02344 16.5 4.03816 16.5 6.52344V9.02344"
        stroke="#BDBDBD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
