import Engine from "../components/Engine";
import axios from "../utils/Axios";
import {
  useFocusable,
  FocusContext,
  setFocus,
  getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import FocusableChild from "../components/FocusableChild";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import StayDatesRangeInput from "../components/StayDatesRangeInput";
import GuestsInput from "../components/GuestsInput";
import Heart from "../svgs/Heart";
import Modal from "../components/Modal";

export default function BookingMainPage() {
  const [hotel, setHotel] = useState(null);
  const [activeImage, setActiveImage] = useState("");
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const { id } = useParams();
  const [favourite, setFavourite] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isInFavourites, setIsInfavorites] = useState(false);

  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(`/v3/hotel/${id}`);
      setHotel(data.content);
      setActiveImage(data.content.coverImage);
      if (await data.content) {
        setFocus("hotel-image-0");
      }
    }
    getData();

    const getFavourites = async () => {
      if (!localStorage.getItem("auth")) {
        return;
      }
      try {
        const { data } = await axios.get("v3/hotel/wishlist/my-wishlist");
        const temp = await data.content.find((item) => item.id === id);
        if (!temp) {
          return;
        }

        setIsInfavorites(true);
        setFavourite(temp);
      } catch (err) {
        console.error(err);
      }
    };
    getFavourites();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const formdata = new FormData(e.target);

    navigate(
      `/rooms-listing/${id}?adults=${formdata.get(
        "adults"
      )}&children=${formdata.get("children")}&infants=${formdata.get(
        "infants"
      )}&location=${formdata.get("location")}&startDate=${formdata.get(
        "startDate"
      )}&endDate=${formdata.get("endDate")}`
    );
  }

  async function toggleWishlist(item) {
    if (!localStorage.getItem("auth")) {
      setFocus("modalCloseButton");
      setModalOpen(true);
      return;
    }

    const action = isInFavourites ? "delete" : "post";
    const endpoint = isInFavourites
      ? `/v1/wishlist/remove/${item}`
      : `/v1/wishlist/add/${item}`;
    try {
      const response = await axios[action](endpoint);
      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        setIsInfavorites((prev) => !prev);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div
          className="home-page p-8 w-full"
          style={{
            marginLeft: "5vw",
            transition: "0.3s",
          }}
        >
          <p className="mb-6 text-2xl">NOMADVERSE</p>
          {hotel && (
            <div className="hotel-detailed">
              <div className="flex">
                <div className="slider mr-8 w-full">
                  <img
                    className="h-[493px] w-full object-cover rounded-lg"
                    src={activeImage}
                    alt=""
                  />
                  <div className="slides flex mt-6 overflow-x-hidden">
                    {[hotel.coverImage, ...hotel.hotelImages].map(
                      (item, index) => {
                        return (
                          <FocusableChild
                            focusKeyParam={`hotel-image-${index}`}
                            onArrowPress={(e) => {
                              if (
                                e == "right" &&
                                [hotel.coverImage, ...hotel.hotelImages]
                                  .length ==
                                  index + 1
                              ) {
                                setFocus("booking-sidebar");
                                return false;
                              }
                              return true;
                            }}
                            onFocus={() => setActiveImage(item)}
                            className={"min-w-[25%] rounded-lg mr-4 last:mr-0"}
                          >
                            <img
                              src={item}
                              className="w-full rounded-sm max-h-[133px] object-cover h-full"
                              alt=""
                            />
                          </FocusableChild>
                        );
                      }
                    )}
                  </div>
                </div>
                <form
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  className="w-[40%]"
                  onSubmit={handleSubmit}
                >
                  <p className="text-5xl mb-8">{hotel.propertyName}</p>
                  <div className="flex items-center mb-8 text-[#8A929F] text-2xl">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.5436 22.2093L17.8862 27.8666C17.6388 28.1143 17.345 28.3107 17.0216 28.4448C16.6983 28.5788 16.3516 28.6478 16.0016 28.6478C15.6515 28.6478 15.3049 28.5788 14.9815 28.4448C14.6581 28.3107 14.3643 28.1143 14.1169 27.8666L8.45823 22.2093C6.96653 20.7175 5.95068 18.8169 5.53915 16.7478C5.12762 14.6786 5.33889 12.534 6.14624 10.5849C6.95359 8.63587 8.32076 6.97 10.0749 5.79795C11.829 4.62591 13.8913 4.00034 16.0009 4.00034C18.1105 4.00034 20.1728 4.62591 21.9269 5.79795C23.681 6.97 25.0482 8.63587 25.8556 10.5849C26.6629 12.534 26.8742 14.6786 26.4626 16.7478C26.0511 18.8169 25.0353 20.7175 23.5436 22.2093Z"
                        stroke="#8A929F"
                        stroke-width="2.4243"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.0002 14.6667C20.0002 15.7275 19.5788 16.745 18.8287 17.4951C18.0785 18.2452 17.0611 18.6667 16.0002 18.6667C14.9394 18.6667 13.922 18.2452 13.1718 17.4951C12.4217 16.745 12.0002 15.7275 12.0002 14.6667C12.0002 13.6058 12.4217 12.5884 13.1718 11.8382C13.922 11.0881 14.9394 10.6667 16.0002 10.6667C17.0611 10.6667 18.0785 11.0881 18.8287 11.8382C19.5788 12.5884 20.0002 13.6058 20.0002 14.6667Z"
                        stroke="#8A929F"
                        stroke-width="2.4243"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p>{hotel.location.formattedAddress}</p>
                  </div>
                  <div className="hotel-buy-sidebar px-8 py-6 bg-[#1F2937] rounded-3xl">
                    <p className="text-sm mb-6">
                      <span className="text-3xl">${hotel.minPrice}</span>
                      /Night
                    </p>
                    <div className="flex flex-col rounded-3xl ">
                      <div className="border border-[#4F4F4F] border-solid rounded-3xl">
                        <StayDatesRangeInput
                          startDate={new Date(query.get("startDate"))}
                          endDate={new Date(query.get("endDate"))}
                          ignoredDirection="up"
                          className="flex-1 p-2 z-[11] rounded-t-3xl"
                        />
                        <div
                          style={{ borderBottom: "1px solid #4F4F4F" }}
                          className="w-full border-b border-[#4F4F4F] "
                        ></div>
                        <GuestsInput
                          className="flex-1 p-4 rounded-tr-none rounded-br-3xl rounded-bl-3xl"
                          onArrowPress={(e) => {
                            if (e === "right") {
                              return false;
                            }
                            if (
                              e === "down" &&
                              document.querySelector(
                                "[focusKey=guestsnumberAdults]"
                              )
                            ) {
                              setFocus("guestsnumberAdults");
                              return false;
                            }

                            return true;
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="h-[74px] mt-6 flex items-center">
                    <FocusableChild
                      className="flex-1 h-full w-[340px] bg-primary-800 rounded-[36px]"
                      focusKeyParam={"booking-sidebar"}
                      buttonClick={true}
                      borderAlways={true}
                    >
                      <button
                        type="submit"
                        className="w-full h-full font-medium text-[18px] border-none outline-none"
                      >
                        Check availibility
                      </button>
                    </FocusableChild>

                    <FocusableChild
                      className={`w-[73px] h-[73px] ml-6 grid place-items-center rounded-full ${
                        isInFavourites ? "bg-red-400" : "bg-primary-800"
                      }`}
                      onEnter={async () => {
                        toggleWishlist(id, false);
                      }}
                      onArrowPress={(e) => {
                        if (e === "right") {
                          return false;
                        }

                        return true;
                      }}
                    >
                      <Heart />
                    </FocusableChild>
                  </div>
                </form>
              </div>

              <div className="flex">
                <div className="w-[50%]">
                  <div className={"mt-6 py-6 pb-0 rounded-lg"} outline={true}>
                    <p className="text-2xl">Facilities</p>
                    <div className="flex justify-between my-5"></div>
                    <FocusableChild outline={true} className="rounded-3xl">
                      <div className="flex flex-wrap mb-8 ">
                        {[
                          ...hotel.generalFacilities,
                          ...hotel.additionalFacilities,
                        ].map((item) => {
                          return (
                            <div className="bg-[#2C364F] flex m-4 p-5 rounded-2xl justify-center items-center">
                              <img
                                src={item.icon}
                                className="max-w-5 mr-1 "
                                alt=""
                              />
                              <p>{item.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    </FocusableChild>
                  </div>
                  <FocusableChild
                    outline={true}
                    className={"mt-6 p-6 rounded-lg"}
                    onArrowPress={(e) => {
                      if (e === "down") {
                        return false;
                      }
                      return true;
                    }}
                  >
                    <p>{hotel.description}</p>
                  </FocusableChild>
                </div>
              </div>
            </div>
          )}
        </div>
      </FocusContext.Provider>
      <Modal
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
        message="You are not authorized yet."
        secondButtonTitle="Go to Login Page"
        secondButtonClose={() => navigate("/login")}
      />
    </>
  );
}
