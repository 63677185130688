import {
  useFocusable,
  FocusContext,
  setFocus,
  getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../utils/Axios";

const tours = {
  casino: {
    description:
      "Feeling adventurous? Dive into our virtual casino for an exciting gaming experience! With every spin and deal, get ready to be swept away in the thrill of the game. Come join the fun from the comfort of your own home.",
    image: "https://i.ibb.co/qJLCzBx/3DBG.png",
    videoHD: "https://nomadao-api-upload.b-cdn.net/videos/fifinal_casino.mp4",
    videoHD: "https://media.w3.org/2010/05/sintel/trailer.mp4",
  },
  "hotel-tour": {
    description: "Hotel Tour",
    image: "https://i.ibb.co/XjrszGT/Hotel.png",
    videoHD: "https://nomadao-api-upload.b-cdn.net/videos/rooms_hotel.mp4",
  },
  "event-hall": {
    image: "https://i.ibb.co/m4vTT9m/Eventhall.png",
    videoHD:
      "https://nomadao-api-upload.b-cdn.net/videos/event_hall_texted.mp4",
    description:
      "Step into a world of entertainment like never before! Join us in our virtual reality event hall, where live shows come to life in stunning detail. Get ready to experience the magic of live events in a whole new dimension.",
  },
  "world-tour": {
    image: "https://i.ibb.co/VDKxwF8/world-tour.png",
    videoHD: "https://nomadao-api-upload.b-cdn.net/videos/3D_Map.mp4",
    description:
      "Discover the world from a whole new perspective! Explore our interactive guide map and uncover hidden treasures and top attractions in breathtaking detail. Get ready to embark on an unforgettable journey right from your fingertips.",
  },
};

export default function IntroPixelStreamingPage() {
  const navigate = useNavigate();
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "pixel-stream-page",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  let { id } = useParams();
  const [tour, setTour] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTours = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/v3/hotel-form/form/pixel-streaming`);
        setTour(await data.content[id]);
        setLoading(false);
        setFocus("pixel-intro-button");
      } catch (err) {
        console.error(err);
      }
    };

    getTours();
    console.log(id);
  }, [id]);

  if (loading) {
    return (
      <p className="w-full h-screen grid place-items-center text-5xl">
        Loading...
      </p>
    );
  }

  if (!tour) {
    return (
      <p className="w-full h-screen grid place-items-center text-5xl">
        Tour not found or is unavailable.
      </p>
    );
  }

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div className="relative w-full h-screen ml-24 flex items-center justify-start">
          <p className="absolute left-6 top-8 text-2xl">NOMADVERSE</p>
          <div className="ml-32 flex flex-col w-min h-minitems-start">
            <p className="w-[785px] mb-16 text-[36px] font-light text-left">
              {tour ? tour.description : "Loading tour information..."}
            </p>
            <FocusableChild
              borderRadius="36px"
              className="w-[340px] h-[73px]"
              focusKeyParam={"pixel-intro-button"}
              onEnter={() => {
                navigate(
                  `/video-player/?videoHD=${tour.videoHD}&videoFHD=${tour.videoFHD}&poster=${tour.image}`
                );
              }}
              onArrowPress={(e) => {
                if (e === "left") {
                  return true;
                }

                return false;
              }}
            >
              <button className="w-full h-full text-center bg-primary-800 border border-white rounded-[36px] font-medium text-xl">
                Start Now
              </button>
            </FocusableChild>
          </div>
          <img
            src={tour.image}
            alt="tour"
            className="absolute right-[50px] h-[868px] -z-50"
          />
        </div>
      </FocusContext.Provider>
    </>
  );
}
