import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useNavigate } from "react-router-dom";
import BackButton from "./../components/BackButton";
import { useEffect, useState } from "react";
import { getToken, removeToken } from "../utils/tokenService";
import axios from "../utils/Axios";
import PersonBig from "../svgs/PersonBig";

const ProfilePage = () => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const navigate = useNavigate();
  const [backButtonSelected, setBackButtonSelected] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const token = getToken();
    if (!token) {
      navigate("/login");
      return;
    }

    const getMe = async () => {
      try {
        const response = await axios.get("/v1/user/profile/me", {
          headers: {
            Authorization: token,
          },
        });

        setUserData(await response.data.content);
      } catch (err) {
        console.error(err);
      }
    };
    getMe();
    setFocus("logOutButton");
  }, []);

  return (
    <main className="relative w-full h-screen ml-24 flex flex-col items-center">
      <FocusContext.Provider value={focusKey}>
        <div className="mt-[290px] flex flex-col items-center">
          <div className="w-40 h-40 grid place-items-center rounded-full border-solid border-2 border-white">
            <PersonBig />
          </div>

          <h2 className="mt-8 font-normal text-[40px]">{userData.fullName}</h2>

          <FocusableChild
            focusKeyParam={"logOutButton"}
            onEnter={() => {
              removeToken();
              navigate("/login");
            }}
            onArrowPress={(e) => {
              if (e === "left") {
                setFocus("backButton");
                return false;
              }
              return true;
            }}
            className="mt-8 rounded-[36px]"
            borderAlways={true}
          >
            <button className="px-6 py-4 rounded-[36px]">Log out</button>
          </FocusableChild>
        </div>

        <FocusableChild
          onEnter={() => navigate("/")}
          onFocus={() => setBackButtonSelected(true)}
          focusKeyParam={"backButton"}
          onArrowPress={(e) => {
            setBackButtonSelected(false);
            if (e === "right") {
              setFocus("logOutButton");
              return false;
            }
            return true;
          }}
          borderHidden={true}
        >
          <BackButton selected={backButtonSelected} />
        </FocusableChild>
      </FocusContext.Provider>
    </main>
  );
};

export default ProfilePage;
