import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PlainArrow from "../svgs/PlainArrow";
import axios from "../utils/Axios";
import Pin from "../svgs/Pin";
import RatingStar from "../svgs/RatingStar";
import PersonRound from "../svgs/PersonRound";
import Bed from "../svgs/Bed";
import Bath from "../svgs/Bath";
import Door from "../svgs/Door";
import Heart from "../svgs/Heart";
import Modal from "../components/Modal";

const FavouritesPage = () => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const navigate = useNavigate();
  const [favourites, setFavourites] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("auth")) {
      setFocus("modalCloseButton");
      setModalOpen(true);
      return;
    }

    const getFavourites = async () => {
      try {
        const { data } = await axios.get("v3/hotel/wishlist/my-wishlist");
        const temp = await data.content.map((item) => {
          return {
            checked: true,
            ...item,
          };
        });
        setFavourites(temp);
      } catch (err) {
        console.error(err);
      }
    };
    getFavourites();
    setFocus("hotel0");
  }, []);

  async function toggleWishlist(item) {
    const isInWishlist = favourites[item].checked;
    const action = isInWishlist ? "delete" : "post";
    const endpoint = isInWishlist
      ? `/v1/wishlist/remove/${favourites[item].id}`
      : `/v1/wishlist/add/${favourites[item].id}`;
    try {
      const response = await axios[action](endpoint);
      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        const temp = [...favourites];
        temp[item].checked = !temp[item].checked;
        setFavourites(temp);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <main className="relative w-full h-screen ml-24 pt-[165px] px-8 flex flex-col items-start">
      <h2 className="mt-[72px] mb-8 text-[32px] font-normal">
        Advanced Search
      </h2>

      <FocusContext.Provider value={focusKey}>
        {favourites.length ? (
          <div className="w-full flex flex-col">
            {favourites.map((hotel, i) => {
              return (
                <div
                  key={i}
                  className="w-[1500px] h-[300px] mb-6 flex items-center"
                >
                  <FocusableChild
                    focusKeyParam={`hotel${i}`}
                    onEnter={() => navigate(`/hotels-detailed/${hotel.id}`)}
                    className="w-full h-full flex rounded-xl p-4 bg-primary-800"
                  >
                    <div className="relative h-full w-[730px] rounded-md overflow-hidden">
                      <img
                        src={hotel.coverImage}
                        alt="hotel"
                        className="w-full object-cover"
                      />
                      {/* 
                      {hotel.hotelRooms[0].discount ? (
                        <span className="absolute top-5 left-5 px-5 py-[2px] rounded-2xl text-textWhite text-[20px] font-normal bg-[#B91C1C]">
                          -{hotel.hotelRooms[0].discount}% today
                        </span>
                      ) : null} */}
                    </div>

                    <div className="flex-1 py-4 flex items-center">
                      <div className="h-full flex flex-col ml-12 items-start">
                        <p className="font-normal text-[28px]">
                          {hotel.propertyName}
                        </p>

                        {/* <p className="mt-6 font-normal text-[24px] text-textGray">
                          {hotel.propertyType} · {hotel.hotelRooms[0].beds} beds
                        </p> */}

                        <p className="flex items-center mt-6 font-normal text-[24px] text-textGray">
                          <Pin />
                          <span className="ml-4">
                            {/* {hotel.postalCode} {hotel.street} */}
                            {hotel.location}
                          </span>
                        </p>

                        <hr className="my-6 border-1 border-white w-20" />

                        <div className="flex items-center">
                          <p className="font-semibold text-[28px]">
                            ${hotel.minPrice}
                          </p>
                          <span className="ml-4 font-normal text-[24px] text-textGray">
                            /night
                          </span>
                          {/* <p className="ml-9 flex items-center">
                            <RatingStar />

                            <span className="ml-4 text-[22px] font-medium">
                              {hotel.stars}
                            </span>
                            <span className="ml-4 text-[24px] font-medium">
                              (198)
                            </span>
                          </p> */}
                        </div>
                      </div>

                      {/* <div className="w-[2px] h-full mx-12 bg-primary-600" /> */}

                      {/* <div className="h-full flex flex-col items-start pr-[110px]">
                        <div className="flex items-center mb-4">
                          <PersonRound />
                          <p className="ml-[26px] font-normal text-[24px] text-textWhite whitespace-nowrap">
                            {hotel.hotelRooms[0].guests} guests
                          </p>
                        </div>
                        <div className="flex items-center mb-4">
                          <Bed />
                          <p className="ml-[26px] font-normal text-[24px] text-textWhite whitespace-nowrap">
                            {hotel.hotelRooms[0].beds} beds
                          </p>
                        </div>
                        <div className="flex items-center mb-4">
                          <Bath />
                          <p className="ml-[26px] font-normal text-[24px] text-textWhite whitespace-nowrap">
                            {hotel.hotelRooms[0].guests} baths
                          </p>
                        </div>
                        <div className="flex items-center mb-4">
                          <Door />
                          <p className="ml-[26px] font-normal text-[24px] text-textWhite whitespace-nowrap">
                            {hotel.hotelRooms[0].bedrooms} bedrooms
                          </p>
                        </div>
                        <div className="flex items-center mb-4">
                          <Door />
                          <p className="ml-[26px] font-normal text-[24px] text-textWhite whitespace-nowrap">
                            {hotel.hotelRooms[0].guests} hall
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </FocusableChild>
                  <FocusableChild
                    className={`w-28 h-28 grid place-items-center ml-12 rounded-full ${
                      hotel.checked ? "bg-red-400" : "bg-primary-800"
                    }`}
                    onEnter={() => toggleWishlist(i)}
                  >
                    <Heart />
                  </FocusableChild>
                </div>
              );
            })}
          </div>
        ) : (
          <h2 className="mt-[72px] mb-8 text-[32px] font-normal -z-10">
            You have not chosen any favourites yet
          </h2>
        )}
      </FocusContext.Provider>
      <Modal
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
        message="You are not authorized yet."
        secondButtonTitle="Go to Login Page"
        secondButtonClose={() => navigate("/login")}
      />
    </main>
  );
};

export default FavouritesPage;
