import React, { useEffect, useState, useRef } from "react";
import {
  useFocusable,
  FocusContext,
} from "@noriginmedia/norigin-spatial-navigation";
import { useSearchParams } from "react-router-dom";
import { Player } from "video-react";
import FocusableChild from "./../components/FocusableChild";

export default function IntroPixelStreamingPage() {
  const { ref, focusKey, focused, focusSelf } = useFocusable({
    focusKey: "video-player-page",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const [query] = useSearchParams();
  const playerRef = useRef(null);
  const playerRef2 = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const [alternateSourceState, setAlternateSourceState] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const handleChangeQuality = () => {
    if (alternateSourceState) {
      setAlternateSourceState((prev) => !prev);
      setCurrentTime(playerRef.current.video.video.currentTime);

      setIsPlaying(true);
      return;
    }

    setAlternateSourceState((prev) => !prev);
    setCurrentTime(playerRef2.current.video.video.currentTime);

    setIsPlaying(true);
  };

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  const handlePlayPause = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
      setIsPlaying(!isPlaying);
      return;
    }
    if (isPlaying) {
      playerRef2.current.pause();
    } else {
      playerRef2.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMute = () => {
    if (playerRef.current) {
      const newMutedState = !isMuted;
      playerRef.current.muted = newMutedState;
      setIsMuted(newMutedState);
      return;
    }
    const newMutedState = !isMuted;
    playerRef2.current.muted = newMutedState;
    setIsMuted(newMutedState);
  };

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div
          className="home-page p-8 pt-12 pb-0 relative"
          style={{ marginLeft: "5vw", transition: "0.3s" }}
        >
          {alternateSourceState ? (
            <Player
              ref={playerRef}
              playsInline
              fluid={false}
              muted={isMuted}
              autoPlay={true}
              width="100%"
              height={980}
              src={query.get("videoFHD")}
              startTime={currentTime}
              poster={query.get("poster")}
            />
          ) : (
            <Player
              ref={playerRef2}
              playsInline
              fluid={false}
              muted={isMuted}
              autoPlay={true}
              width="100%"
              height={980}
              src={query.get("videoHD")}
              startTime={currentTime}
              poster={query.get("poster")}
            />
          )}

          <div className="controls flex justify-center absolute w-[1760px] bottom-16">
            <FocusableChild
              className="bg-[#2C364F] w-32 flex m-4 p-5 rounded-full justify-center items-center"
              onEnter={handlePlayPause}
              borderAlways={true}
              onArrowPress={(e) => {
                if (e === "up" || e === "down") {
                  return false;
                }

                return true;
              }}
            >
              <button>{isPlaying ? "Pause" : "Play"}</button>
            </FocusableChild>
            <FocusableChild
              className="bg-[#2C364F] w-32 flex m-4 p-5 rounded-full justify-center items-center"
              onEnter={handleMute}
              borderAlways={true}
              onArrowPress={(e) => {
                if (e === "up" || e === "down") {
                  return false;
                }

                return true;
              }}
            >
              <button>{isMuted ? "Unmute" : "Mute"}</button>
            </FocusableChild>

            <FocusableChild
              className="bg-[#2C364F] w-32 flex m-4 p-5 rounded-full justify-center items-center"
              onEnter={handleChangeQuality}
              borderAlways={true}
              onArrowPress={(e) => {
                if (e === "up" || e === "down" || e === "right") {
                  return false;
                }

                return true;
              }}
            >
              <button>
                {alternateSourceState ? "Switch to hd" : "Switch to full-hd"}
              </button>
            </FocusableChild>
          </div>
        </div>
      </FocusContext.Provider>
    </>
  );
}
