import {
	useFocusable,
	FocusContext,
	setFocus,
	getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import FocusableChild from "../components/FocusableChild";
import Engine from "../components/Engine";
import HomeSpecialOffers from "../components/HomeSpecialOffers";
import EngineTabs from "../components/EngineTabs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../components/Modal";

export default function HomePage() {
	const [activeTab, setActiveTab] = useState("apartments");
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	let [query, setQuery] = useSearchParams();

	useEffect(() => {
		const newParams = new URLSearchParams(query);

		// Set the 'home' parameter to 'true'
		newParams.set("home", "true");
		setQuery(newParams);
		const handleKeyDown = (event) => {
			// Check if the key code matches the Tizen back button key code
			if (event.keyCode === 10009) {
				console.log("Back button pressed");
				// Handle the back button action here
				event.preventDefault(); // Prevent default behavior if necessary
				// Optionally, navigate or perform other actions
				setModalOpen(true);
				setFocus("modalCloseButton");
			}
		};

		// Add event listener for keydown events
		document.addEventListener("keydown", handleKeyDown);

		// Clean up event listener
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const tabs = [
		{
			name: "Apartments",
		},
		{
			name: "Event Hall",
		},
		{
			name: "3D Casino",
		},
		{
			name: "3D Hotel tour",
		},
		{
			name: "3D World Tour",
		},
	];

	const pixelStreamCards = [
		{
			name: "3D Hotel Tour",
			src: "https://nomadao-api-upload.b-cdn.net/images/3D-HotelTour.jpg",
			href: "/pixel-streaming/hotel-tour",
		},
		{
			name: "3D Casino",
			src: "https://nomadao-api-upload.b-cdn.net/images/3D-Casino.jpg",
			href: "/pixel-streaming/casino",
		},
		{
			name: "3D Event Hall",
			src: "https://nomadao-api-upload.b-cdn.net/images/3D-Event_Hall.jpg",
			href: "/pixel-streaming/event-hall",
		},
		{
			name: "Virtual World",
			src: "https://nomadao-api-upload.b-cdn.net/images/Virtual-World.jpg",
			href: "/pixel-streaming/world-tour",
		},
	];

	const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
		focusKey: "home",
		focusable: true,
		saveLastFocusedChild: true,
		trackChildren: true,
		autoRestoreFocus: true,
		isFocusBoundary: false,
		forceFocus: true,
		preferredChildFocusKey: null,
		onEnterPress: () => {},
		onEnterRelease: () => {},
		onArrowPress: (e) => {},
		onFocus: () => {},
		onBlur: () => {},
		extraProps: { foo: "bar" },
	});

	useEffect(() => {
		setFocus("home-banner");
	}, []);

	return (
		<>
			<FocusContext.Provider value={focusKey}>
				<div
					className="home-page p-8"
					style={{
						marginLeft: "5vw",
						transition: "0.3s",
					}}
				>
					{/* <div className="product-tabs flex justify-between pb-6 mb-6 border-b-[1px] border-solid border-[#828282]">
            {tabs.map((item) => {
              return (
                <>
                  <FocusableChild
                    className={`tab-item py-4 rounded-lg px-11  ${
                      activeTab == item.name ? "bg-[#3D28DFCC]" : ""
                    }`}
                    onFocus={() => {
                      setActiveTab(item.name);
                      console.log("hi");
                    }}
                  >
                    {item.name}
                  </FocusableChild>
                </>
              );
            })}
          </div> */}
					<p className="mb-6 text-2xl">NOMADVERSE</p>
					<FocusableChild
						onEnter={() => {
							navigate("booking");
						}}
						focusKeyParam={"home-banner"}
						direction={"up"}
						onArrowPress={(e) => {
							if (e === "up") {
								window.scrollTo(0, 0);
								return false;
							}
							if (e === "left") {
								setFocus("home2");
								return false;
							}
							if (e === "right") {
								return false;
							}
							if (e === "down") {
								setFocus("engine-location");
								return false;
							}
							return true;
						}}
						outline={true}
						className="home-page-banner rounded-lg relative"
					>
						<img
							className="home-banner-image rounded-lg"
							src="https://nomadao-api-upload.b-cdn.net/images/home_banner.png"
							alt=""
						/>
						<div className="flex absolute left-0 top-0 h-full w-full justify-center items-center">
							<div className="home-banner-description z-[1] max-w-[60%] absolute text-center">
								<p className="text-5xl mb-8 font-normal	">
									Book stays from a selection of 500 000+ hotels
								</p>
								<p className="text-4xl mb-8 font-normal	">
									Experience Secure, Instant Payments with Cryptocurrency
								</p>
								<div className="flex justify-center">
									<div>
										<button
											className="px-[84px] py-[25px] bg-[#2E3238] rounded-full bg-opacity-50"
											style={{ border: "1px solid #4F46E5" }}
										>
											Start Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="home-banner-filter absolute rounded-lg left-0 top-0 w-full h-full"></div>
					</FocusableChild>
					{/* <HomeSpecialOffers /> */}
					<div className="flex justify-center">
						<EngineTabs />
					</div>
					<div className="text-center mt-24">
						<p className="text-3xl font-light">Advanced Search</p>
					</div>
					<Engine home={true} windowsOnTop={true} />
					<div className="pixel-stream-cards grid grid-cols-4 mt-24">
						{pixelStreamCards.map((item, index) => {
							return (
								<FocusableChild
									onEnter={() => {
										navigate(item.href);
									}}
									onArrowPress={(e) => {
										if (
											e === "right" &&
											index === pixelStreamCards.length - 1
										) {
											return false;
										}

										if (e === "down") {
											window.scrollTo(0, document.documentElement.scrollHeight);
											return false;
										}

										return true;
									}}
									className={`relative rounded-3xl mx-4 max-h-[244px]`}
									outline={true}
								>
									<img
										src={item.src}
										className="rounded-3xl h-full w-full"
										alt=""
									/>
									<div className="absolute flex justify-center w-full bottom-0 left-0 p-4 home-banner-filter-second rounded-b-3xl">
										<p>{item.name}</p>
									</div>
								</FocusableChild>
							);
						})}
					</div>
				</div>
			</FocusContext.Provider>
			<Modal
				modalOpen={modalOpen}
				modalClose={() => setModalOpen(false)}
				message="Do you want to leave NOMADVERSE?"
				secondButtonTitle="Close NOMADVERSE"
				secondButtonClose={() => {
					try {
						window.tizen.application.getCurrentApplication().exit();
					} catch (error) {
						console.error("Error exiting application: ", error);
					}
				}}
			/>
		</>
	);
}
