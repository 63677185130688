import BackIcon from "../svgs/BackIcon";

const BackButton = ({ selected }) => {
  return (
    <div className="absolute left-16 bottom-20 flex items-center justify-center">
      <div
        className={`${
          selected && "border-solid border-2 border-white"
        } w-[73px] h-[73px] bg-primary-800 rounded-full grid place-items-center`}
      >
        <BackIcon />
      </div>
      <p className="ml-5 font-medium text-base">Back</p>
    </div>
  );
};

export default BackButton;
