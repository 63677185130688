const Pin = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4498 22.8378L18.7925 28.4952C18.5451 28.7428 18.2513 28.9393 17.9279 29.0733C17.6045 29.2074 17.2579 29.2764 16.9078 29.2764C16.5578 29.2764 16.2111 29.2074 15.8877 29.0733C15.5644 28.9393 15.2706 28.7428 15.0232 28.4952L9.36448 22.8378C7.87278 21.3461 6.85693 19.4454 6.4454 17.3763C6.03387 15.3072 6.24514 13.1625 7.05249 11.2135C7.85984 9.26444 9.22701 7.59857 10.9811 6.42653C12.7352 5.25448 14.7975 4.62891 16.9072 4.62891C19.0168 4.62891 21.0791 5.25448 22.8332 6.42653C24.5873 7.59857 25.9545 9.26444 26.7618 11.2135C27.5692 13.1625 27.7804 15.3072 27.3689 17.3763C26.9574 19.4454 25.9415 21.3461 24.4498 22.8378Z"
        stroke="#8A929F"
        stroke-width="2.4243"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9065 15.2949C20.9065 16.3558 20.4851 17.3732 19.7349 18.1233C18.9848 18.8735 17.9674 19.2949 16.9065 19.2949C15.8456 19.2949 14.8282 18.8735 14.0781 18.1233C13.3279 17.3732 12.9065 16.3558 12.9065 15.2949C12.9065 14.2341 13.3279 13.2166 14.0781 12.4665C14.8282 11.7163 15.8456 11.2949 16.9065 11.2949C17.9674 11.2949 18.9848 11.7163 19.7349 12.4665C20.4851 13.2166 20.9065 14.2341 20.9065 15.2949Z"
        stroke="#8A929F"
        stroke-width="2.4243"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Pin;
