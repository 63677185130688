import React, { useState } from "react";
import PlainArrow from "../svgs/PlainArrow";

const DatePickerCustomHeaderTwoMonth = ({
	monthDate,
	decreaseMonth,
	increaseMonth,
	arrowsShown = false,
}) => {
	return (
		<div className="flex items-center justify-between">
			{arrowsShown ? (
				<button
					type="button"
					aria-label="Previous Month"
					className="rotate-180 w-11 h-11 transition-all grid place-items-center rounded-full bg-none hover:bg-[#37415199]"
					onClick={decreaseMonth}
				>
					<PlainArrow />
				</button>
			) : null}
			<span className="react-datepicker__current-month flex-1 text-center">
				{monthDate.toLocaleString("en-US", {
					month: "long",
					year: "numeric",
				})}
			</span>
			{arrowsShown ? (
				<button
					type="button"
					aria-label="Next Month"
					className="w-11 h-11 transition-all grid place-items-center rounded-full bg-none hover:bg-[#37415199]"
					onClick={increaseMonth}
				>
					<PlainArrow />
				</button>
			) : null}
		</div>
	);
};

export default DatePickerCustomHeaderTwoMonth;
