import EngineTabs from "../components/EngineTabs";
import Engine from "../components/Engine";
import axios from "../utils/Axios";
import {
  useFocusable,
  FocusContext,
  setFocus,
  getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import FocusableChild from "../components/FocusableChild";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackIcon from "../svgs/BackIcon";
import { useParams } from "react-router-dom";

export default function HotelsListingPage() {
  const tags = [
    "Near your Location",
    "Season Sales",
    "Family Trip",
    "Join the team",
    "For 1 Week",
    "Explore",
    "Closer",
    "Go Wild",
  ];
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [hotel, setHotel] = useState(null);
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const { id } = useParams();
  useEffect(() => {
    focusSelf();
    async function getData() {
      const { data } = await axios.get(`/v3/hotel/${id}`);
      console.log(data);
      setHotel(data.content);
      setRooms(data.content.hotelRooms);
    }
    getData();
  }, []);

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div
          className="home-page hotels-listing-page p-8"
          style={{
            marginLeft: "5vw",
            transition: "0.3s",
          }}
        >
          <p className="mb-6 text-2xl">NOMADVERSE</p>
          <div className="flex justify-center">
            <EngineTabs />
          </div>
          <div className="flex items-center mb-8">
            <FocusableChild
              className={
                "bg-[#1F2937] mr-12 justify-center items-center flex w-16 h-16 rounded-full"
              }
              onEnter={() => {
                navigate(-1);
              }}
              onArrowPress={(e) => {
                if (e === "right" || e === "up") {
                  return false;
                }

                return true;
              }}
            >
              <BackIcon />
            </FocusableChild>
            {hotel && (
              <>
                <h1 className="text-5xl mr-4">{hotel.propertyName}</h1>
                <div className="flex items-center text-[#8A929F] text-2xl">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.5436 22.2093L17.8862 27.8666C17.6388 28.1143 17.345 28.3107 17.0216 28.4448C16.6983 28.5788 16.3516 28.6478 16.0016 28.6478C15.6515 28.6478 15.3049 28.5788 14.9815 28.4448C14.6581 28.3107 14.3643 28.1143 14.1169 27.8666L8.45823 22.2093C6.96653 20.7175 5.95068 18.8169 5.53915 16.7478C5.12762 14.6786 5.33889 12.534 6.14624 10.5849C6.95359 8.63587 8.32076 6.97 10.0749 5.79795C11.829 4.62591 13.8913 4.00034 16.0009 4.00034C18.1105 4.00034 20.1728 4.62591 21.9269 5.79795C23.681 6.97 25.0482 8.63587 25.8556 10.5849C26.6629 12.534 26.8742 14.6786 26.4626 16.7478C26.0511 18.8169 25.0353 20.7175 23.5436 22.2093Z"
                      stroke="#8A929F"
                      stroke-width="2.4243"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.0002 14.6667C20.0002 15.7275 19.5788 16.745 18.8287 17.4951C18.0785 18.2452 17.0611 18.6667 16.0002 18.6667C14.9394 18.6667 13.922 18.2452 13.1718 17.4951C12.4217 16.745 12.0002 15.7275 12.0002 14.6667C12.0002 13.6058 12.4217 12.5884 13.1718 11.8382C13.922 11.0881 14.9394 10.6667 16.0002 10.6667C17.0611 10.6667 18.0785 11.0881 18.8287 11.8382C19.5788 12.5884 20.0002 13.6058 20.0002 14.6667Z"
                      stroke="#8A929F"
                      stroke-width="2.4243"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>{hotel.location.formattedAddress}</p>
                </div>
              </>
            )}
          </div>
          <div className="hotels-content">
            <p className="text-3xl mb-6">Available Rooms</p>
            <div className="flex h-[802px]">
              {preview && (
                <div className="preview-hotel mr-8  h-[592px]  bg-[#1F2937] flex-1">
                  <div className="hotel-card-poster">
                    <img
                      className="h-[532px] rounded-md"
                      src={preview.roomImages[0]}
                      alt=""
                    />
                    <div className="flex justify-around my-5">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-3"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M12.5818 3.75C11.6285 3.75 10.7496 3.98444 9.94512 4.45333C9.14068 4.92222 8.50401 5.55889 8.03512 6.36333C7.56623 7.16778 7.33179 8.04667 7.33179 9C7.33179 9.89111 7.53845 10.7189 7.95179 11.4833C8.36734 12.25 8.93401 12.8756 9.65179 13.36C8.76068 13.7511 7.96734 14.29 7.27179 14.9767C6.57845 15.6633 6.04401 16.4522 5.66845 17.3433C5.27734 18.2656 5.08179 19.2344 5.08179 20.25H6.58179C6.58179 19.1567 6.85179 18.1522 7.39179 17.2367C7.92957 16.3233 8.65512 15.5978 9.56845 15.06C10.484 14.52 11.4885 14.25 12.5818 14.25C13.6751 14.25 14.6796 14.52 15.5951 15.06C16.5085 15.5978 17.234 16.3233 17.7718 17.2367C18.3118 18.1522 18.5818 19.1567 18.5818 20.25H20.0818C20.0818 19.2344 19.8862 18.2656 19.4951 17.3433C19.1196 16.4522 18.5851 15.6633 17.8918 14.9767C17.1962 14.29 16.4029 13.7511 15.5118 13.36C16.2296 12.8756 16.7962 12.25 17.2118 11.4833C17.6251 10.7189 17.8318 9.89111 17.8318 9C17.8318 8.04667 17.5973 7.16778 17.1285 6.36333C16.6596 5.55889 16.0229 4.92222 15.2185 4.45333C14.414 3.98444 13.5351 3.75 12.5818 3.75ZM12.5818 5.25C13.2685 5.25 13.8973 5.41778 14.4685 5.75333C15.0396 6.08889 15.4929 6.54222 15.8285 7.11333C16.164 7.68444 16.3318 8.31333 16.3318 9C16.3318 9.68667 16.164 10.3156 15.8285 10.8867C15.4929 11.4578 15.0396 11.9111 14.4685 12.2467C13.8973 12.5822 13.2685 12.75 12.5818 12.75C11.8951 12.75 11.2662 12.5822 10.6951 12.2467C10.124 11.9111 9.67068 11.4578 9.33512 10.8867C8.99957 10.3156 8.83179 9.68667 8.83179 9C8.83179 8.31333 8.99957 7.68444 9.33512 7.11333C9.67068 6.54222 10.124 6.08889 10.6951 5.75333C11.2662 5.41778 11.8951 5.25 12.5818 5.25Z"
                            fill="#E0E0E0"
                          />
                        </svg>
                        <p>guests: {preview.guests}</p>
                      </div>
                      <div className="flex items-center">
                        <svg
                          className="mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M20.0818 4.5H5.08179C4.4729 4.5 3.94512 4.72222 3.49845 5.16667C3.05401 5.61333 2.83179 6.14111 2.83179 6.75V11.8367C2.59845 12.0544 2.41512 12.3044 2.28179 12.5867C2.14845 12.8667 2.08179 13.1711 2.08179 13.5V20.25H5.83179V18.75H19.3318V20.25H23.0818V13.5C23.0818 13.1711 23.0151 12.8667 22.8818 12.5867C22.7485 12.3044 22.5651 12.0544 22.3318 11.8367V6.75C22.3318 6.14111 22.1096 5.61333 21.6651 5.16667C21.2185 4.72222 20.6907 4.5 20.0818 4.5ZM5.08179 6H20.0818C20.284 6 20.4596 6.07444 20.6085 6.22333C20.7573 6.37222 20.8318 6.54778 20.8318 6.75V11.25H19.3318V10.5C19.3318 9.89111 19.1096 9.36333 18.6651 8.91667C18.2185 8.47222 17.6907 8.25 17.0818 8.25H14.0818C13.5196 8.25 13.0196 8.44556 12.5818 8.83667C12.144 8.44556 11.644 8.25 11.0818 8.25H8.08179C7.4729 8.25 6.94512 8.47222 6.49845 8.91667C6.05401 9.36333 5.83179 9.89111 5.83179 10.5V11.25H4.33179V6.75C4.33179 6.54778 4.40623 6.37222 4.55512 6.22333C4.70401 6.07444 4.87957 6 5.08179 6ZM8.08179 9.75H11.0818C11.284 9.75 11.4596 9.82444 11.6085 9.97333C11.7573 10.1222 11.8318 10.2978 11.8318 10.5V11.25H7.33179V10.5C7.33179 10.2978 7.40623 10.1222 7.55512 9.97333C7.70401 9.82444 7.87957 9.75 8.08179 9.75ZM14.0818 9.75H17.0818C17.284 9.75 17.4596 9.82444 17.6085 9.97333C17.7573 10.1222 17.8318 10.2978 17.8318 10.5V11.25H13.3318V10.5C13.3318 10.2978 13.4062 10.1222 13.5551 9.97333C13.704 9.82444 13.8796 9.75 14.0818 9.75ZM4.33179 12.75H20.8318C21.034 12.75 21.2096 12.8244 21.3585 12.9733C21.5073 13.1222 21.5818 13.2978 21.5818 13.5V18.75H20.8318V17.25H4.33179V18.75H3.58179V13.5C3.58179 13.2978 3.65623 13.1222 3.80512 12.9733C3.95401 12.8244 4.12957 12.75 4.33179 12.75Z"
                            fill="#E0E0E0"
                          />
                        </svg>
                        <p>beds: {preview.beds}</p>
                      </div>
                      <div className="flex items-center">
                        <svg
                          className="mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M20.0815 3C19.5349 3 19.0304 3.13333 18.5682 3.4C18.1082 3.66444 17.746 4.02667 17.4815 4.48667C17.2149 4.94889 17.0815 5.45333 17.0815 6H15.5815V7.5H20.0815V6H18.5815C18.5815 5.54667 18.7182 5.18333 18.9915 4.91C19.2649 4.63667 19.6282 4.5 20.0815 4.5C20.5349 4.5 20.8982 4.63667 21.1715 4.91C21.4449 5.18333 21.5815 5.54667 21.5815 6V10.5H1.33154V12H2.22154L3.37154 17.72C3.46488 18.1089 3.64377 18.4522 3.90821 18.75C4.17488 19.0478 4.49599 19.2589 4.87154 19.3833L4.33154 21H5.83154L6.32488 19.5H18.8382L19.3315 21H20.8315L20.2915 19.3833C20.6827 19.2589 21.0193 19.0478 21.3015 18.75C21.5815 18.4522 21.7682 18.1089 21.8615 17.72L22.9415 12H23.8315V10.5H23.0815V6C23.0815 5.45333 22.9482 4.94889 22.6815 4.48667C22.4171 4.02667 22.0549 3.66444 21.5949 3.4C21.1327 3.13333 20.6282 3 20.0815 3ZM4.84821 17.39L3.74488 12H21.4415L20.3849 17.4133C20.3382 17.5867 20.2493 17.7278 20.1182 17.8367C19.9849 17.9456 19.8238 18 19.6349 18H5.59821C5.40932 18 5.24932 17.9411 5.11821 17.8233C4.98488 17.7078 4.89488 17.5633 4.84821 17.39Z"
                            fill="#E0E0E0"
                          />
                        </svg>
                        <p>baths: {preview.bathroom}</p>
                      </div>
                      <div className="flex items-center">
                        <svg
                          className="mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M20.0818 4.5H5.08179C4.4729 4.5 3.94512 4.72222 3.49845 5.16667C3.05401 5.61333 2.83179 6.14111 2.83179 6.75V11.8367C2.59845 12.0544 2.41512 12.3044 2.28179 12.5867C2.14845 12.8667 2.08179 13.1711 2.08179 13.5V20.25H5.83179V18.75H19.3318V20.25H23.0818V13.5C23.0818 13.1711 23.0151 12.8667 22.8818 12.5867C22.7485 12.3044 22.5651 12.0544 22.3318 11.8367V6.75C22.3318 6.14111 22.1096 5.61333 21.6651 5.16667C21.2185 4.72222 20.6907 4.5 20.0818 4.5ZM5.08179 6H20.0818C20.284 6 20.4596 6.07444 20.6085 6.22333C20.7573 6.37222 20.8318 6.54778 20.8318 6.75V11.25H19.3318V10.5C19.3318 9.89111 19.1096 9.36333 18.6651 8.91667C18.2185 8.47222 17.6907 8.25 17.0818 8.25H14.0818C13.5196 8.25 13.0196 8.44556 12.5818 8.83667C12.144 8.44556 11.644 8.25 11.0818 8.25H8.08179C7.4729 8.25 6.94512 8.47222 6.49845 8.91667C6.05401 9.36333 5.83179 9.89111 5.83179 10.5V11.25H4.33179V6.75C4.33179 6.54778 4.40623 6.37222 4.55512 6.22333C4.70401 6.07444 4.87957 6 5.08179 6ZM8.08179 9.75H11.0818C11.284 9.75 11.4596 9.82444 11.6085 9.97333C11.7573 10.1222 11.8318 10.2978 11.8318 10.5V11.25H7.33179V10.5C7.33179 10.2978 7.40623 10.1222 7.55512 9.97333C7.70401 9.82444 7.87957 9.75 8.08179 9.75ZM14.0818 9.75H17.0818C17.284 9.75 17.4596 9.82444 17.6085 9.97333C17.7573 10.1222 17.8318 10.2978 17.8318 10.5V11.25H13.3318V10.5C13.3318 10.2978 13.4062 10.1222 13.5551 9.97333C13.704 9.82444 13.8796 9.75 14.0818 9.75ZM4.33179 12.75H20.8318C21.034 12.75 21.2096 12.8244 21.3585 12.9733C21.5073 13.1222 21.5818 13.2978 21.5818 13.5V18.75H20.8318V17.25H4.33179V18.75H3.58179V13.5C3.58179 13.2978 3.65623 13.1222 3.80512 12.9733C3.95401 12.8244 4.12957 12.75 4.33179 12.75Z"
                            fill="#E0E0E0"
                          />
                        </svg>
                        <p>bedroom: {preview.bedrooms}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="hotels-list overflow-y-scroll flex flex-1 flex-col w-full">
                {rooms.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`${i === 0 ? "" : "mt-7"} flex items-center`}
                    >
                      <FocusableChild
                        onFocus={() => {
                          setPreview(item);
                        }}
                        onEnter={() => {
                          navigate(
                            `/room-detailed/${id}?room=${
                              item._id
                            }&adults=${query.get(
                              "adults"
                            )}&children=${query.get(
                              "children"
                            )}&infants=${query.get(
                              "infants"
                            )}&location=${query.get(
                              "location"
                            )}&startDate=${query.get(
                              "startDate"
                            )}&endDate=${query.get("endDate")}`
                          );
                        }}
                        onArrowPress={(e) => {
                          if (e === "right") {
                            return false;
                          }

                          if (e === "down" && i === rooms.length - 1) {
                            return false;
                          }

                          return true;
                        }}
                        className="hotel-list-card w-full flex"
                      >
                        <div className="hotel-card-poster flex-1">
                          <img
                            className="h-[297px] object-cover rounded-md"
                            src={item.roomImages[0]}
                            alt=""
                          />
                        </div>

                        <div className="ml-6 flex-1 flex flex-col justify-between">
                          <div>
                            <p className="mb-4 text-2xl">{item.groupName}</p>
                            <p className="text-[#8A929F] mb-4">
                              {item.roomType} * {item.bedrooms} beds
                            </p>

                            <div className="bg-white h-[1px] w-[56px]"></div>
                          </div>

                          <div className="flex justify-between">
                            <p className="text-[#8A929F]">
                              <span className="text-white">${item.price} </span>
                              /night
                            </p>
                          </div>
                        </div>
                      </FocusableChild>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </FocusContext.Provider>
    </>
  );
}
