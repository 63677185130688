import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import QRCode from "react-qr-code";
import FocusableChild from "../components/FocusableChild";
import { useEffect, useState } from "react";
import axios from "../utils/Axios";
import { useParams, useSearchParams } from "react-router-dom";

export default function QRPayment() {
  const [query] = useSearchParams();
  const { id } = useParams();

  const [hotel, setHotel] = useState(null);
  const [room, setRoom] = useState(null);
  const [qrPaymentURL, setQrPaymentURL] = useState("");
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });
  function convertDateString(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
}
function calculateBookingPrice(startDate, endDate, dailyRate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const timeDiff = end - start + 1;

  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  const totalPrice = diffDays * dailyRate;

  return totalPrice;
}


  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(`/v3/hotel/${id}`);
      
      setHotel(data.content);
      const room = data.content.hotelRooms.filter(
        (room) => room._id === query.get("room")
      )[0];
      setRoom(room);
      const { data: qr } = await axios.post(`/v1/booking/${data.content._id}/${room._id}`, {
        startDate: convertDateString(query.get("startDate")),
        endDate: convertDateString(query.get("endDate")),
        price: calculateBookingPrice(query.get("startDate"), query.get("endDate"), room.price),
      });
      setQrPaymentURL(qr.content.payment_url);
    }
    getData();
    focusSelf();
  }, []);
  return (
    <>
      <FocusContext.Provider value={"hi"}>
        {hotel && (
          <div
            className="home-page p-8 flex justify-center items-center"
            style={{
              minHeight: "100vh",
              marginLeft: "5vw",
              transition: "0.3s",
            }}
          >
            <div className="mr-32">
              <div className="bg-[#1F2937] p-6 rounded-2xl text-2xl">
                <div className="h-[198px] w-[437px]">
                  <img
                    src={hotel.coverImage}
                    className="rounded-2xl mb-8 object-cover h-full w-full"
                    alt=""
                  />
                </div>
                <p className="my-4 text-3xl">{hotel.propertyName}</p>
                <div className="flex items-center text-2xl">
                  <svg
                    className="mr-3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.5436 22.2093L17.8862 27.8666C17.6388 28.1143 17.345 28.3107 17.0216 28.4448C16.6983 28.5788 16.3516 28.6478 16.0016 28.6478C15.6515 28.6478 15.3049 28.5788 14.9815 28.4448C14.6581 28.3107 14.3643 28.1143 14.1169 27.8666L8.45823 22.2093C6.96653 20.7175 5.95068 18.8169 5.53915 16.7478C5.12762 14.6786 5.33889 12.534 6.14624 10.5849C6.95359 8.63587 8.32076 6.97 10.0749 5.79795C11.829 4.62591 13.8913 4.00034 16.0009 4.00034C18.1105 4.00034 20.1728 4.62591 21.9269 5.79795C23.681 6.97 25.0482 8.63587 25.8556 10.5849C26.6629 12.534 26.8742 14.6786 26.4626 16.7478C26.0511 18.8169 25.0353 20.7175 23.5436 22.2093Z"
                      stroke="#8A929F"
                      stroke-width="2.4243"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.0002 14.6667C20.0002 15.7275 19.5788 16.745 18.8287 17.4951C18.0785 18.2452 17.0611 18.6667 16.0002 18.6667C14.9394 18.6667 13.922 18.2452 13.1718 17.4951C12.4217 16.745 12.0002 15.7275 12.0002 14.6667C12.0002 13.6058 12.4217 12.5884 13.1718 11.8382C13.922 11.0881 14.9394 10.6667 16.0002 10.6667C17.0611 10.6667 18.0785 11.0881 18.8287 11.8382C19.5788 12.5884 20.0002 13.6058 20.0002 14.6667Z"
                      stroke="#8A929F"
                      stroke-width="2.4243"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>{hotel.location.formattedAddress}</p>
                </div>
                <p className=" my-4 ">{room.groupName}</p>
                <p className="mb-8">
                  {room.roomType} * {room.beds} beds
                </p>
                <p className="pb-4 border-b border-[#4F4F4F] border-solid">
                  Summary
                </p>
                <div className="flex justify-between my-4">
                  <p>Check in</p>
                  <p>
                    {new Date(query.get("startDate")).toLocaleDateString(
                      "ka-GE",
                      { month: "short", day: "2-digit" }
                    )}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Check out</p>
                  <p>
                    {new Date(query.get("endDate")).toLocaleDateString(
                      "ka-GE",
                      {
                        month: "short",
                        day: "2-digit",
                      }
                    )}
                  </p>
                </div>
                <div className="flex justify-between my-4">
                  <p>Guests</p>
                  <div className="text-end">
                    <p>{query.get("adults")} adults</p>
                    <p>{query.get("children")} children</p>
                    <p>{query.get("infants")} infants</p>
                  </div>
                </div>
              </div>
            </div>
            {qrPaymentURL && <div
              style={{
                background: "white",
                width: "488px",
                padding: "20px",
                borderRadius: "16px",
              }}
            >
              <QRCode
                size={456}
                fgColor="#1F2937"
                style={{ height: "auto", width: "448px" }}
                viewBox={`0 0 256 256`}
                value={qrPaymentURL}
              />
            </div>}
            {/* <FocusableChild>hi</FocusableChild> */}
          </div>
        )}
      </FocusContext.Provider>
    </>
  );
}
