import "./App.css";
import "../node_modules/video-react/dist/video-react.css";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import {
  init,
  resume,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";

function App(props) {
  init({
    debug: false,
    // visualDebug: true
  });
  const navigate = useNavigate();
  const [query] = useSearchParams();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 10009) {
        if (query.get("home")) {
          return;
        }
        navigate(-1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    resume();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, query]);

  return (
    <div className="tv-layout">
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default App;
