import FocusableChild from "../components/FocusableChild";

const recoverySchema = [
  {
    id: "email",
    type: "text",
    placeholder: "Email",
  },
];

const PasswordRecovery = () => {
  return (
    <>
      {recoverySchema.map((field, i) => (
        <FocusableChild
          key={i}
          className="login-input-wrapper"
          borderAlways={true}
          onArrowPress={(e) => {
            if (e === "right") {
              return false;
            }

            if (e === "up") {
              return false;
            }

            return true;
          }}
        >
          <input
            type={field.type}
            name={field.id}
            placeholder={field.placeholder}
            className={`login-input ${
              i === 0 ? "bg-primary-800" : "bg-transparent"
            }`}
          />
        </FocusableChild>
      ))}
    </>
  );
};

export default PasswordRecovery;
