import EngineTabs from "../components/EngineTabs";
import Engine from "../components/Engine";
import axios from "../utils/Axios";
import {
	useFocusable,
	FocusContext,
	setFocus,
	getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import FocusableChild from "../components/FocusableChild";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackIcon from "../svgs/BackIcon";
import Heart from "../svgs/Heart";
import Modal from "../components/Modal";

export default function HotelsListingPage() {
	const tags = [
		"Near your Location",
		"Season Sales",
		"Family Trip",
		"Join the team",
		"For 1 Week",
		"Explore",
		"Closer",
		"Go Wild",
	];

	const [query] = useSearchParams();
	const navigate = useNavigate();
	const [preview, setPreview] = useState(null);
	const [hotels, setHotels] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);

	const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
		focusKey: "home",
		focusable: true,
		saveLastFocusedChild: true,
		trackChildren: true,
		autoRestoreFocus: true,
		isFocusBoundary: false,
		forceFocus: true,
		preferredChildFocusKey: null,
		onEnterPress: () => {},
		onEnterRelease: () => {},
		onArrowPress: (e) => {},
		onFocus: () => {},
		onBlur: () => {},
		extraProps: { foo: "bar" },
	});

	async function toggleWishlist(item, isInWishlist) {
		if (!localStorage.getItem("auth")) {
			setFocus("modalCloseButton");
			setModalOpen(true);
			return;
		}

		const action = isInWishlist ? "delete" : "post";
		const endpoint = isInWishlist
			? `/v1/wishlist/remove/${item.id}`
			: `/v1/wishlist/add/${item.id}`;

		try {
			await axios[action](endpoint);
			const updatedHotels = hotels.content.map((hotel) =>
				hotel.id === item.id
					? { ...hotel, isInWishlist: !isInWishlist }
					: hotel,
			);
			setHotels((prevHotels) => ({ ...prevHotels, content: updatedHotels }));
		} catch (error) {
			console.error("Failed to update wishlist", error);
		}
	}

	useEffect(() => {
		async function getData() {
			const { data } = await axios.post("/v3/hotel-filter", {
				location: query.get("location"),
				pets: query.get("pets") ? true : null,
				resorts: query.get("resorts") ? true : null,
				hotSales: query.get("hotSales") ? true : null,
				favouriteHotels: query.get("favouriteHotels") ? true : null,
			});
			setHotels(data);
		}
		getData();
	}, [query]);

	useEffect(() => {
		setFocus("back-button");
	}, []);

	return (
		<>
			<FocusContext.Provider value={focusKey}>
				<div
					className="home-page hotels-listing-page p-8 pb-0"
					style={{
						marginLeft: "5vw",
						transition: "0.3s",
					}}
				>
					<p className="mb-6 text-2xl">NOMADVERSE</p>
					<div className="flex justify-center">
						<EngineTabs />
					</div>
					<div className="flex items-center justify-center my-20">
						<FocusableChild
							focusKeyParam={"back-button"}
							className={
								"bg-[#1F2937] mr-6 justify-center items-center w-[92px] h-[92px] flex shadow-2xl rounded-full"
							}
							onEnter={() => {
								navigate(-1);
							}}
							onArrowPress={(e) => {
								if (e === "up") {
									return false;
								}
								return true;
							}}
						>
							<BackIcon />
						</FocusableChild>
						<div className="w-[1000px]">
							<Engine ignoredDirection="up" />
						</div>
					</div>
					<div className="hotels-content">
						<p className="text-[32px] font-medium mb-6">
							Results ({hotels.total})
						</p>
						<div className="flex h-[600px]">
							{preview && (
								<div className="preview-hotel mr-8 h-[592px] rounded-b-[20px]  bg-[#1F2937] flex-1">
									<div className="hotel-card-poster">
										<img
											className="h-[532px] rounded-[20px]"
											src={preview.coverImage}
											alt=""
										/>
										<div className="flex justify-around my-5">
											{preview.generalFacilities.length > 3
												? preview.generalFacilities.slice(0, 4).map((item) => {
														return (
															<div className="flex items-center">
																<img
																	src={item.icon}
																	className="max-w-5 mr-2 "
																	alt=""
																/>
																<p>{item.name}</p>
															</div>
														);
													})
												: preview.generalFacilities.map((item) => {
														return (
															<div className="flex items-center">
																<img
																	src={item.icon}
																	className="max-w-5 mr-2 "
																	alt=""
																/>
																<p>{item.name}</p>
															</div>
														);
													})}
										</div>
									</div>
								</div>
							)}
							<div className="hotels-list overflow-y-scroll flex flex-1 flex-col w-full">
								{hotels.content &&
									[...hotels.content].map((item, i) => {
										return (
											<div key={i} className="flex items-center mb-8 last:mb-0">
												<FocusableChild
													onFocus={() => {
														setPreview(item);
													}}
													onEnter={() => {
														navigate(
															`/hotels-detailed/${item.id}/?adults=${query.get(
																"adults",
															)}&children=${query.get(
																"children",
															)}&infants=${query.get(
																"infants",
															)}&location=${query.get(
																"location",
															)}&startDate=${query.get(
																"startDate",
															)}&endDate=${query.get("endDate")}`,
														);
													}}
													onArrowPress={(e) => {
														if (
															e === "down" &&
															i === [...hotels.content].length - 1
														) {
															return false;
														}
													}}
													className="hotel-list-card w-full flex mr-6"
												>
													<div className="hotel-card-poster flex-1 mr-6">
														<img
															className="h-[297px] object-cover rounded-[8px]"
															src={item.coverImage}
															alt=""
														/>
													</div>
													<div className="flex-1 flex flex-col justify-between">
														<div>
															<p className="text-[#8A929F] mb-4">
																{item.rentalForm} {item.bedroom}
															</p>
															<p className="mb-4 text-3xl">
																{item.propertyName}
															</p>
															<div className="grid grid-cols-2 mb-4 text-[#8A929F]">
																{item.generalFacilities.length > 3
																	? item.generalFacilities
																			.slice(0, 4)
																			.map((item) => {
																				return (
																					<div className="flex items-center mb-4">
																						<img
																							src={item.icon}
																							className="max-w-5 mr-2 "
																							alt=""
																						/>
																						<p>{item.name}</p>
																					</div>
																				);
																			})
																	: item.generalFacilities.map((item) => {
																			return (
																				<div className="flex items-center mb-4">
																					<img
																						src={item.icon}
																						className="max-w-5 mr-2 "
																						alt=""
																					/>
																					<p>{item.name}</p>
																				</div>
																			);
																		})}
															</div>
															<div className="flex mb-6 text-[20px] items-center text-[#8A929F]">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="24"
																	height="24"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M11.9626 11.1045L9.13391 13.9331C9.01021 14.057 8.86331 14.1552 8.70162 14.2222C8.53993 14.2892 8.36661 14.3237 8.19158 14.3237C8.01655 14.3237 7.84323 14.2892 7.68154 14.2222C7.51985 14.1552 7.37295 14.057 7.24925 13.9331L4.41991 11.1045C3.67406 10.3586 3.16614 9.40827 2.96037 8.37371C2.75461 7.33916 2.86024 6.26681 3.26391 5.29229C3.66759 4.31777 4.35118 3.48483 5.22823 2.89881C6.10529 2.31279 7.13642 2 8.19125 2C9.24607 2 10.2772 2.31279 11.1543 2.89881C12.0313 3.48483 12.7149 4.31777 13.1186 5.29229C13.5223 6.26681 13.6279 7.33916 13.4221 8.37371C13.2164 9.40827 12.7084 10.3586 11.9626 11.1045Z"
																		stroke="#8A929F"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	/>
																	<path
																		d="M10.1914 7.3335C10.1914 7.86393 9.98069 8.37264 9.60562 8.74771C9.23055 9.12278 8.72184 9.3335 8.19141 9.3335C7.66097 9.3335 7.15227 9.12278 6.77719 8.74771C6.40212 8.37264 6.19141 7.86393 6.19141 7.3335C6.19141 6.80306 6.40212 6.29436 6.77719 5.91928C7.15227 5.54421 7.66097 5.3335 8.19141 5.3335C8.72184 5.3335 9.23055 5.54421 9.60562 5.91928C9.98069 6.29436 10.1914 6.80306 10.1914 7.3335Z"
																		stroke="#8A929F"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	/>
																</svg>
																<p className="ml-2">{item.location}</p>
															</div>
															<div className="bg-white h-[1px] w-[56px]"></div>
															<div className="flex mt-4 text-2xl justify-between">
																<p className="text-[#8A929F]">
																	<span className="text-white">
																		${item.minPrice}{" "}
																	</span>
																	/night
																</p>
															</div>
														</div>
													</div>
												</FocusableChild>
												{!item.isInWishlist ? (
													<FocusableChild
														className={`bg-[#1F2937] w-[117px] h-[108px] grid place-items-center rounded-full`}
														onEnter={async () => {
															toggleWishlist(item, false);
														}}
														onArrowPress={(e) => {
															if (e === "right") {
																return false;
															}

															if (
																e === "down" &&
																i === [...hotels.content].length - 1
															) {
																return false;
															}
														}}
													>
														<Heart />
													</FocusableChild>
												) : (
													<FocusableChild
														className={`justify-center bg-red-400 items-center flex w-16 h-16 rounded-full`}
														onEnter={async () => {
															toggleWishlist(item, true);
														}}
														onArrowPress={(e) => {
															if (
																e === "down" &&
																i === [...hotels.content].length - 1
															) {
																return false;
															}
														}}
													>
														<Heart />
													</FocusableChild>
												)}
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</FocusContext.Provider>

			<Modal
				modalOpen={modalOpen}
				modalClose={() => setModalOpen(false)}
				message="You are not authorized yet."
				secondButtonTitle="Go to Login Page"
				secondButtonClose={() => navigate("/login")}
			/>
		</>
	);
}
