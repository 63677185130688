import { useState } from "react";
import FocusableChild from "../components/FocusableChild";
import LockIcon from "../svgs/LockIcon";
import Person from "../svgs/Person";

const signInSchema = [
  {
    id: "email",
    type: "text",
    placeholder: "Email",
    icon: <Person />,
  },
  {
    id: "password",
    type: "password",
    placeholder: "Password",
    icon: <LockIcon />,
  },
];

const SignIn = () => {
  const [activeInput, setActiveInput] = useState(0);

  return (
    <>
      {signInSchema.map((field, i) => (
        <FocusableChild
          key={i}
          onFocus={() => setActiveInput(i)}
          className="login-input-wrapper"
          borderAlways={true}
          onArrowPress={(e) => {
            if (e === "right") {
              return false;
            }

            if (e === "up" && i === 0) {
              return false;
            }

            return true;
          }}
        >
          <div
            className={`login-input flex items-center justify-center ${
              i === activeInput ? "bg-primary-800" : "bg-transparent"
            }`}
          >
            <div className="mr-2">{field.icon}</div>
            <input
              type={field.type}
              name={field.id}
              placeholder={field.placeholder}
              className="flex-1 h-full bg-transparent outline-none border-none"
            />
          </div>
        </FocusableChild>
      ))}
    </>
  );
};

export default SignIn;
