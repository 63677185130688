import React from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import FocusableChild from "./FocusableChild";
import ButtonSubmit from "./ButtonSubmit";
import { useNavigate } from "react-router-dom";

const Engine = ({ home, ignoredDirection, windowsOnTop = false }) => {
	const navigate = useNavigate();
	function handleSubmit(e) {
		e.preventDefault();
		const formdata = new FormData(e.target);

		navigate(
			`/hotels-listing?adults=${formdata.get("adults")}&children=${formdata.get(
				"children",
			)}&infants=${formdata.get("infants")}&location=${formdata.get(
				"location",
			)}&startDate=${formdata.get("startDate")}&endDate=${formdata.get(
				"endDate",
			)}`,
		);
	}
	const renderForm = () => {
		return (
			<form
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
					}
				}}
				onSubmit={handleSubmit}
				className={`engine h-[92px] flex items-center mt-8 max-w-[900px] ${
					home ? "m-auto" : ""
				} mb-8`}
			>
				<div className="w-full mr-3 relative flex rounded-full h-full shadow-xl dark:shadow-2xl bg-[#1F2937] dark:bg-[#1F2937] ">
					<LocationInput
						ignoredDirection={ignoredDirection}
						windowsOnTop={windowsOnTop}
						className="flex-[1.5]"
					/>
					<div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 pl-2"></div>
					<StayDatesRangeInput
						ignoredDirection={ignoredDirection}
						windowsOnTop={windowsOnTop}
						className="flex-1"
					/>
					<div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 pl-2"></div>
					<GuestsInput
						ignoredDirection={ignoredDirection}
						windowsOnTop={windowsOnTop}
						className="flex-1"
					/>
				</div>
				<FocusableChild
					outline={true}
					focusKeyParam={"engine-search-button"}
					className={"dark:bg-[#1F2937] rounded-full"}
					buttonClick={true}
					onArrowPress={(e) => {
						if (e === "right") {
							return false;
						}

						if (ignoredDirection) {
							if (e === ignoredDirection) {
								return false;
							}
						}

						return true;
					}}
				>
					<ButtonSubmit />
				</FocusableChild>
			</form>
		);
	};

	return renderForm();
};

export default Engine;
