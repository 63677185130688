import {
	FocusContext,
	setFocus,
	useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useNavigate } from "react-router-dom";
import BackButton from "./../components/BackButton";
import { useEffect, useState } from "react";
import axios from "../utils/Axios";
import Engine from "../components/Engine";

const BookingMainPage = () => {
	const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
		focusKey: "home",
		focusable: true,
		saveLastFocusedChild: true,
		trackChildren: true,
		autoRestoreFocus: true,
		isFocusBoundary: false,
		forceFocus: true,
		preferredChildFocusKey: null,
		onEnterPress: () => {},
		onEnterRelease: () => {},
		onArrowPress: (e) => {},
		onFocus: () => {},
		onBlur: () => {},
		extraProps: { foo: "bar" },
	});

	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [backButtonSelected, setBackButtonSelected] = useState(false);
	const [activeCategory, setActiveCategory] = useState(null);

	useEffect(() => {
		const getCategories = async () => {
			const { data } = await axios.get(`/v3/hotel-form/filter/category-form`);
			setCategories(data.content);
		};

		getCategories();
	}, []);

	return (
		<main className="relative w-full h-screen ml-24 pt-[165px] px-8 flex flex-col items-center">
			<FocusContext.Provider value={focusKey}>
				<h3 className="w-full text-[36px] font-medium mb-4">Categories</h3>
				<div className="w-full flex items-center justify-center">
					{categories.map((category, i) => {
						return (
							<FocusableChild
								key={i}
								onFocus={() => setActiveCategory(i)}
								focusKeyParam={`categoryItem${i}`}
								onEnter={() =>
									navigate(
										`/hotels-listing?&${
											category.key
										}&startDate=${new Date()}&endDate=${new Date()}&adults=1&children=0&infants=0`,
									)
								}
								onArrowPress={(e) => {
									setActiveCategory(null);
									if (e === "left" && i === 0) {
										setFocus("home2");
										return false;
									}
									if (e === "right") {
										if (i + 1 === categories.length) {
											setFocus(`categoryItem${i}`);

											return false;
										}
										setFocus(`categoryItem${i + 1}`);
										return false;
									}
									if (e === "left" && i !== 0) {
										setFocus(`categoryItem${i - 1}`);
										return false;
									}
									return true;
								}}
								className={`relative flex-1 h-[442px] flex flex-col items-center rounded-2xl overflow-hidden ${
									i + 1 === categories.length ? "mr-0" : "mr-8"
								}`}
							>
								<img
									src={category.icon}
									alt={category.icon}
									className="mt-[90px]"
								/>

								<p className="mt-16 text-[32px] font-medium">{category.name}</p>

								<img
									src={category.background}
									alt={category.background}
									className="absolute top-0 left-0 h-full w-full object-cover opacity-90 -z-50"
								/>
								<div
									className={`absolute w-full h-full opacity-95 -z-10 ${
										i === activeCategory ? "bg-primary-800" : "bg-primary-600"
									}`}
								></div>
							</FocusableChild>
						);
					})}
				</div>

				<h2 className="mt-[72px] text-[32px] font-normal">Advanced Search</h2>

				<div className="w-full">
					<Engine home={true} windowsOnTop={true} />
				</div>

				<FocusableChild
					onEnter={() => navigate("/")}
					onFocus={() => setBackButtonSelected(true)}
					focusKeyParam={"backButton"}
					onArrowPress={(e) => {
						setBackButtonSelected(false);
						if (e === "right" || e === "up") {
							setFocus("engine-location");
							return false;
						}
						if (e === "left") {
							setFocus("home2");
							return false;
						}
						return true;
					}}
					borderHidden={true}
				>
					<BackButton selected={backButtonSelected} />
				</FocusableChild>
			</FocusContext.Provider>
		</main>
	);
};

export default BookingMainPage;
