import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useRef, useState } from "react";
import FocusableChild from "../components/FocusableChild";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../utils/Axios";
import BackButton from "../components/BackButton";
import PlainArrow from "../svgs/PlainArrow";
import QRCode from "react-qr-code";
import { saveToken } from "../utils/tokenService";
import Modal from "../components/Modal";

const LoginLayout = ({ children }) => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const [location, setLocation] = useState(false);
  const { pathname } = useLocation();

  const [backButtonSelected, setBackButtonSelected] = useState(false);
  const [forgotButtonSelected, setForgotButtonSelected] = useState(false);

  const navigate = useNavigate();

  const submitButtonRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    focusSelf();
  }, []);

  useEffect(() => {
    switch (pathname) {
      case "/sign-in":
        setLocation(1);
        break;
      case "/sign-up":
        setLocation(2);
        break;
      case "/password-recovery":
        setLocation(3);
        break;
      default:
        break;
    }
  }, [pathname]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    if (formData.get("prefix")) {
      const prefix = formData.get("prefix");
      const phone = formData.get("phone");
      formData.append("phoneNumber", "+" + prefix + phone);
      formData.delete("prefix");
    }
    const formDataObj = {};
    for (const [key, value] of formData.entries()) {
      formDataObj[key] = value;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const endpoint =
        location === 1
          ? "/v1/user/sign-in"
          : location === 2
          ? "/v1/user/sign-up"
          : "/v1/user/password/change";

      const response = await axios.post(
        endpoint,
        JSON.stringify(formDataObj),
        config
      );

      switch (response.data.message) {
        case "Account Created Successfully":
          navigate("/sign-in");
          break;

        case "user logged in successfully":
          saveToken(response.data.content.accessToken);
          navigate("/");
          break;

        default:
          break;
      }
    } catch (err) {
      console.error(err);

      if (err.response && err.response.data) {
        const message = Array.isArray(err.response.data.message)
          ? err.response.data.message[0]
          : err.response.data.message;

        setErrorMessage(message);
        setModalOpen(true);
        setFocus("modalCloseButton");
      } else {
        setErrorMessage("An unexpected error occurred.");
        setModalOpen(true);
        setFocus("modalCloseButton");
      }
    }
  };

  return (
    <main
      //For QR code block
      // className={`relative w-full h-screen ml-24 flex items-center ${
      //   location === 2 ? "justify-center" : "pl-[180px]"
      // }`}
      className={`relative w-full h-screen ml-24 flex items-center justify-center`}
    >
      <p className="absolute left-8 top-8 text-2xl">NOMADVERSE</p>

      <FocusContext.Provider value={focusKey}>
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          className="login-form mt-24"
          onSubmit={handleLoginSubmit}
        >
          {location !== 2 ? (
            <h2 className="login-header">
              {location === 1 ? (
                <>
                  Fill the fields or use QR code
                  <span className="pl-8 font-normal italic text-3xl">
                    to ease your life
                  </span>
                </>
              ) : (
                <h2 className="font-normal text-[45px]">Password Recovery</h2>
              )}
            </h2>
          ) : (
            <h2 className="absolute w-max -top-[105px] left-1/2 -translate-x-1/2 text-start text-5xl text-white font-normal pb-6">
              Please fill all the fields
            </h2>
          )}

          {location === 3 ? (
            <p className="mb-10 font-normal text-[18px]">
              You will receive new password
            </p>
          ) : null}

          {children}

          <FocusableChild
            onEnter={() => submitButtonRef.current.click()}
            onArrowPress={(e) => {
              if (e === "right") {
                return false;
              }

              setForgotButtonSelected(false);
              if (e === "down" && location === 3) {
                setFocus("backButton");
                return false;
              }

              return true;
            }}
            focusKeyParam={"submitButton"}
            className="login-button-wrapper"
            borderAlways={true}
          >
            <button
              className="login-submit"
              type="submit"
              ref={submitButtonRef}
            >
              <span className="mr-2">
                {location === 1
                  ? "Sign in"
                  : location === 2
                  ? "CREATE ACCOUNT"
                  : "Continue"}
              </span>
              <PlainArrow />
            </button>
          </FocusableChild>
          {location !== 3 ? (
            <FocusableChild
              className="mt-10"
              borderHidden={true}
              onFocus={() => setForgotButtonSelected(true)}
              onArrowPress={(e) => {
                if (e === "right") {
                  return false;
                }

                setForgotButtonSelected(false);
                if (e === "down" || e === "left") {
                  setFocus("backButton");
                  return false;
                }

                return true;
              }}
              onEnter={() => navigate("/password-recovery")}
            >
              <p className={forgotButtonSelected ? "underline" : null}>
                Forgot something?
              </p>
            </FocusableChild>
          ) : null}
        </form>
        <FocusableChild
          onEnter={() => navigate("/login")}
          onFocus={() => setBackButtonSelected(true)}
          focusKeyParam={"backButton"}
          onArrowPress={(e) => {
            if (e === "down") {
              return false;
            }
            setBackButtonSelected(false);
            if (e === "right" || e === "up") {
              setFocus("submitButton");
              return false;
            }
            if (e === "left") {
              setFocus("home2");
              return false;
            }
            return true;
          }}
          borderHidden={true}
        >
          <BackButton selected={backButtonSelected} />
        </FocusableChild>
      </FocusContext.Provider>

      {/* <div>
        <h2>
          {location === 1 ? (
            <div className="qr-code-wrapper ml-[273px]">
              <QRCode
                style={{ width: "100%", height: "100%" }}
                viewBox={`0 0 256 256`}
                value="hi"
              />
            </div>
          ) : null}
        </h2>
      </div> */}

      <Modal
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
        message={errorMessage}
      />
    </main>
  );
};

export default LoginLayout;
