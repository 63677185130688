export const saveToken = (token) => {
  localStorage.setItem("auth", token);
};

export const getToken = () => {
  return localStorage.getItem("auth");
};

export const removeToken = () => {
  localStorage.removeItem("auth");
};
