import React, { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "./NcInputNumber";
import ClearDataButton from "./ClearDataButton";

import { UserPlusIcon } from "@heroicons/react/24/outline";
import FocusableChild from "./FocusableChild";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useSearchParams } from "react-router-dom";

const GuestsInput = ({
	fieldClassName = "[ nc-hero-field-padding ]",
	className = "[ nc-flex-1 ]",
	buttonSubmitHref = "/listing-stay-map",
	hasButtonSubmit = true,
	onArrowPress,
	ignoredDirection,
	windowsOnTop = false,
}) => {
	const [query] = useSearchParams();
	const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(() => {
		if (query.get("adults")) {
			return Number(query.get("adults"));
		} else {
			return 2;
		}
	});
	const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(() => {
		if (query.get("children")) {
			return Number(query.get("children"));
		} else {
			return 1;
		}
	});
	const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(() => {
		if (query.get("infants")) {
			return Number(query.get("infants"));
		} else {
			return 1;
		}
	});
	const guestsRef = useRef(null);
	const guestsContainer = useRef(null);
	const handleChangeData = (value, type) => {
		let newValue = {
			guestAdults: guestAdultsInputValue,
			guestChildren: guestChildrenInputValue,
			guestInfants: guestInfantsInputValue,
		};
		if (type === "guestAdults") {
			setGuestAdultsInputValue(value);
			newValue.guestAdults = value;
		}
		if (type === "guestChildren") {
			setGuestChildrenInputValue(value);
			newValue.guestChildren = value;
		}
		if (type === "guestInfants") {
			setGuestInfantsInputValue(value);
			newValue.guestInfants = value;
		}
	};

	const totalGuests =
		guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

	return (
		<>
			<FocusableChild
				onBlur={() => {
					guestsContainer.current.blur();
				}}
				onEnter={() => {
					guestsRef.current.click();
					if (windowsOnTop) {
						setFocus("guestsnumberInfants");
					} else {
						setFocus("guestsnumberAdults");
					}
				}}
				onArrowPress={
					onArrowPress
						? onArrowPress
						: (e) => {
								if (e == "left") {
									setFocus("engine-calendar");
									return false;
								}

								if (
									e === "down" &&
									document.querySelector("[focusKey=guestsnumberAdults]") &&
									windowsOnTop === false
								) {
									setFocus("guestsnumberAdults");
									return false;
								}

								if (
									e === "up" &&
									document.querySelector("[focusKey=guestsnumberAdults]") &&
									windowsOnTop === true
								) {
									setFocus("guestsnumberAdults");
									return false;
								}

								if (ignoredDirection) {
									if (e === ignoredDirection) {
										return false;
									}
								}

								return true;
							}
				}
				direction={"left || down"}
				outline={true}
				focusKeyParam={"engine-guests"}
				ignoreClick
				className={`flex relative ${className} rounded-r-full items-center`}
			>
				<Popover ref={guestsContainer}>
					{({ open }) => (
						<>
							<div
								className={`flex-1 py-3 z-10 flex items-center rounded-r-full focus:outline-none ${
									open ? "nc-hero-field-focused" : ""
								}`}
							>
								<Popover.Button
									ref={guestsRef}
									className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
								>
									<div className="text-neutral-300 dark:text-neutral-400">
										<UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
									</div>
									<div className="flex-grow">
										<input
											name="adults"
											value={guestAdultsInputValue}
											style={{ visibility: "hidden", position: "absolute" }}
										/>
										<input
											name="children"
											value={guestChildrenInputValue}
											style={{ visibility: "hidden", position: "absolute" }}
										/>
										<input
											name="infants"
											value={guestInfantsInputValue}
											style={{ visibility: "hidden", position: "absolute" }}
										/>
										<span className="block xl:text-lg font-semibold">
											{totalGuests || ""} Guests
										</span>
										<span className="block mt-1  text-neutral-400 leading-none font-light">
											{totalGuests ? "Guests" : "Add guests"}
										</span>
									</div>

									{/* {!!totalGuests && open && (
                    <ClearDataButton
                      onClick={() => {
                        setGuestAdultsInputValue(0);
                        setGuestChildrenInputValue(0);
                        setGuestInfantsInputValue(0);
                      }}
                    />
                  )} */}
								</Popover.Button>
							</div>

							{/* BUTTON SUBMIT OF FORM */}
							{open && (
								<div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-0.5 bg-[#1F2937] dark:bg-[#1F2937]"></div>
							)}
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel
									className={`absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-[#1F2937] dark:bg-[#1F2937] py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ${windowsOnTop ? "bottom-full mb-3" : "top-full mt-3"}`}
								>
									<NcInputNumber
										className="w-full"
										defaultValue={guestAdultsInputValue}
										onChange={(value) => handleChangeData(value, "guestAdults")}
										min={1}
										label="Adults"
										desc="Ages 13 or above"
									/>
									<NcInputNumber
										className="w-full mt-6"
										defaultValue={guestChildrenInputValue}
										onChange={(value) =>
											handleChangeData(value, "guestChildren")
										}
										label="Children"
										desc="Ages 2–12"
									/>

									<NcInputNumber
										className="w-full mt-6"
										defaultValue={guestInfantsInputValue}
										onChange={(value) =>
											handleChangeData(value, "guestInfants")
										}
										label="Infants"
										desc="Ages 0–2"
									/>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
			</FocusableChild>
		</>
	);
};

export default GuestsInput;
