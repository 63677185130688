import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import FocusableChild from "./FocusableChild";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";

const NcInputNumber = ({
	className = "w-full",
	name,
	defaultValue = 0,
	min = 0,
	max,
	onChange,
	label,
	desc,
}) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleClickDecrement = () => {
		if (min >= value) return;
		setValue((state) => {
			return state - 1;
		});
		onChange?.(value - 1);
	};
	const handleClickIncrement = () => {
		if (max && max <= value) return;
		setValue((state) => {
			return state + 1;
		});
		onChange?.(value + 1);
	};

	const handlePlusIconArrowPress = (e) => {
		if (e === "down" && label === "Infants") {
			setFocus("engine-guests");
			return false;
		}
		if (e === "up" && label === "Adults") {
			setFocus("engine-guests");
			return false;
		}
		return true;
	};

	const renderLabel = () => {
		return (
			<div className="flex flex-col">
				<span className="font-medium text-[#1F2937] dark:text-neutral-200">
					{label}
				</span>
				{desc && (
					<span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
						{desc}
					</span>
				)}
			</div>
		);
	};

	return (
		<div
			className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
			data-nc-id="NcInputNumber"
		>
			{label && renderLabel()}

			<div
				className={`nc-NcInputNumber flex items-center justify-between w-28`}
			>
				<FocusableChild
					onArrowPress={handlePlusIconArrowPress}
					focusKeyParam={`guestsnumber${label}`}
					className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-[#1F2937] dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
					type="button"
					onEnter={handleClickDecrement}
					disabled={min >= value}
				>
					<MinusIcon className="w-4 h-4" />
				</FocusableChild>
				<input
					name={name}
					size={`${value}`.length}
					value={value}
					className="resetInput"
				/>
				<FocusableChild
					onArrowPress={handlePlusIconArrowPress}
					className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-[#1F2937] dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
					type="button"
					onEnter={handleClickIncrement}
					disabled={max ? max <= value : false}
				>
					<PlusIcon className="w-4 h-4" />
				</FocusableChild>
			</div>
		</div>
	);
};

export default NcInputNumber;
