const InfoIcon = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_314_916)">
        <path
          d="M17 0.5C21.4181 0.5 25.4187 2.29125 28.3137 5.18625C31.2087 8.08188 33 12.0819 33 16.5C33 20.9181 31.2087 24.9187 28.3137 27.8137C25.4187 30.7087 21.4181 32.5 17 32.5C12.5819 32.5 8.58125 30.7087 5.68625 27.8137C2.79125 24.9187 1 20.9181 1 16.5C1 12.0819 2.79125 8.08188 5.68625 5.18625C8.58125 2.29125 12.5819 0.5 17 0.5ZM18.1094 21.8906H19.9312V23.7231H14.0694V21.8906H15.8669V16.1194H14.0694V14.2869H18.1094V21.8906ZM16.3631 12.4925C15.9206 12.4894 15.5431 12.3344 15.2256 12.02C14.9081 11.7069 14.7531 11.3256 14.7531 10.8794C14.7531 10.4413 14.9081 10.0631 15.2256 9.74938C15.5431 9.43563 15.9206 9.2775 16.3631 9.2775C16.7975 9.2775 17.175 9.43563 17.4931 9.74938C17.8138 10.0631 17.9725 10.4413 17.9725 10.8794C17.9725 11.1744 17.8975 11.4462 17.75 11.6881C17.6025 11.9337 17.4094 12.13 17.1681 12.2738C16.9263 12.4175 16.6575 12.4894 16.3631 12.4925ZM27.1069 6.39313C24.5206 3.8075 20.9469 2.2075 17 2.2075C13.0531 2.2075 9.47937 3.8075 6.89313 6.39313C4.3075 8.98 2.7075 12.5531 2.7075 16.5C2.7075 20.4469 4.3075 24.0206 6.89313 26.6069C9.47937 29.1925 13.0531 30.7925 17 30.7925C20.9469 30.7925 24.5206 29.1925 27.1069 26.6069C29.6925 24.0206 31.2925 20.4469 31.2925 16.5C31.2925 12.5531 29.6925 8.98 27.1069 6.39313Z"
          fill="#BDBDBD"
          stroke="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_314_916">
          <rect width="34" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
