import {
	useFocusable,
	setFocus,
} from "@noriginmedia/norigin-spatial-navigation";

function FocusableChild({
	children,
	className,
	onEnter,
	borderRadius,
	outline,
	menuItem,
	onArrowPress,
	focusKeyParam,
	onFocus,
	onBlur,
	scale,
	buttonClick,
	borderHidden,
	borderAlways,
	focusInputOnEnter = true,
	ignoreClick = false,
}) {
	const { ref, focused, focusKey } = useFocusable({
		focusKey: focusKeyParam,
		onArrowPress(e) {
			const input = ref.current.querySelector("input");

			const startDateInput = ref.current.querySelector(
				'input[name="startDate"]',
			);
			const endDateInput = ref.current.querySelector('input[name="endDate"]');
			const adultsInput = ref.current.querySelector('input[name="adults"]');
			const childrenInput = ref.current.querySelector('input[name="children"]');
			const infantsInput = ref.current.querySelector('input[name="infants"]');

			if (
				startDateInput ||
				endDateInput ||
				adultsInput ||
				childrenInput ||
				infantsInput
			) {
				if (onArrowPress) {
					return onArrowPress(e);
				}
			}

			if (
				ref.current &&
				input &&
				input.type === "text" &&
				document.activeElement === input
			) {
				const currentPos = input.selectionStart;
				if (e === "left" && currentPos > 0) {
					input.setSelectionRange(currentPos - 1, currentPos - 1);
					input.focus();
					return false;
				} else if (e === "right" && currentPos < input.value.length) {
					input.setSelectionRange(currentPos + 1, currentPos + 1);
					input.focus();
					return false;
				}
			}

			if (onArrowPress) {
				return onArrowPress(e);
			}
		},
		onEnterPress() {
			const select = ref.current.querySelector("select");
			const button = ref.current.querySelector("button");
			const input = ref.current.querySelector("input");

			if (select) {
				select.addEventListener("mouseover", () => {
					this.size = 4;
				});
			}

			if (buttonClick && button) {
				button.click();
			}

			if (input) {
				if (document.activeElement === input) {
					input.blur();
				} else {
					input.focus();
				}
			}

			if (onEnter) {
				onEnter();
			}
		},

		onFocus() {
			ref.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "start",
			});

			const input = ref.current.querySelector("input");
			if (input && !focusInputOnEnter) {
				input.focus();
			} else {
				ref.current.focus();
			}
			if (onFocus) onFocus();
		},
		onBlur: () => {
			const input = ref.current.querySelector("input");
			if (input) {
				input.blur();
			}
			if (onBlur) onBlur();
		},
	});

	const manualEnter = (e) => {
		if (ignoreClick) return;

		const select = ref.current.querySelector("select");
		const button = ref.current.querySelector("button");
		const input = ref.current.querySelector("input");

		if (select) {
			select.addEventListener("mouseover", () => {
				this.size = 4;
			});
		}

		if (buttonClick && button) {
			button.click();
		}

		if (onEnter) {
			onEnter();
		}

		setFocus(focusKey);

		if (input) {
			if (document.activeElement === input) {
				input.blur();
			} else {
				input.focus();
			}
		}
	};

	return (
		<div
			tabIndex={"-1"}
			className={className}
			style={{
				// boxShadow: focused && "0px 0px 14px 8px #bebebe",
				border:
					!outline && focused
						? borderHidden
							? "3px solid transparent"
							: "3px solid white"
						: borderAlways
							? "2px solid #8E918F"
							: "",
				boxShadow: focused && outline && "0 0 0 3px rgb(236, 236, 236)",
				borderRadius: focused && borderRadius,
				transform: focused && scale && "scale(1.05)",
				cursor: "pointer",
			}}
			ref={ref}
			focused={focused}
			focusKey={focusKey}
			onClick={manualEnter}
			onMouseUp={() => {}}
		>
			{children}
		</div>
	);
}

export default FocusableChild;
