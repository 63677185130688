import React from "react";

const Person = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57757 14.5051C3.1628 15.3475 -0.546637 17.0676 1.71266 19.2201C2.81631 20.2715 4.04549 21.0235 5.59087 21.0235H14.4091C15.9545 21.0235 17.1837 20.2715 18.2873 19.2201C20.5466 17.0676 16.8372 15.3475 15.4224 14.5051C12.1048 12.5296 7.89519 12.5296 4.57757 14.5051Z"
        stroke="#BDBDBD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 5.52344C14.5 8.00872 12.4853 10.0234 10 10.0234C7.51472 10.0234 5.5 8.00872 5.5 5.52344C5.5 3.03816 7.51472 1.02344 10 1.02344C12.4853 1.02344 14.5 3.03816 14.5 5.52344Z"
        stroke="#BDBDBD"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default Person;
