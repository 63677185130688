export default function EngineTabs() {
  const tabs = ["Apartments", "Experiences", "Cars", "Flights"];
  return (
    <div className="hidden ">
      {tabs.map((tab) => {
        return (
          <div
            className="px-2 py-4 mr-4"
            style={{ borderBottom: tab == "Apartments" && "1px solid white" }}
            key={`tab-${tab}`}
          >
            <p>{tab}</p>
          </div>
        );
      })}
    </div>
  );
}
