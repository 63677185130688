import FocusableChild from "../components/FocusableChild";
import {
  useFocusable,
  FocusContext,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";

const InfoPage = () => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "pixel-stream-page",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const version = "01.02.0424";

  return (
    <div className="relative w-full h-screen">
      <FocusContext.Provider value={focusKey}>
        <FocusableChild
          borderHidden={true}
          onArrowPress={(e) => setFocus("home2")}
        ></FocusableChild>
      </FocusContext.Provider>
      <p className="absolute left-32 top-8 text-2xl">NOMADVERSE</p>
      <p className="absolute right-[130px] top-[126px] text-[45px] font-normal">
        Version {version}.
      </p>
    </div>
  );
};

export default InfoPage;
