import { createBrowserRouter } from "react-router-dom";
import IntroPixelSteramingPage from "../pages/IntroPixelStreamingPage";
import App from "../App";
import HomePage from "../pages/HomePage";
import Error from "../pages/Error";
import BookingMainPage from "../pages/BookingMainPage";
import HotelsListingPage from "../pages/HotelsListingPage";
import RoomsListingPage from "../pages/RoomsListingPage";
import DetailedHotelPage from "../pages/DetailedHotelPage";
import DetailedRoomPage from "../pages/DetailedRoomPage";
import QRPayment from "../pages/QRPayment";
import SignIn from "../pages/SignIn";
import LoginLayout from "../layouts/LoginLayout";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import PasswordRecovery from "../pages/PasswordRecovery";
import ProfilePage from "../pages/ProfilePage";
import FavouritesPage from "../pages/FavouritesPage";
import InfoPage from "../pages/InfoPage";
import VideoPlayerPage from "../pages/VideoPlayerPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/booking",
        element: <BookingMainPage />,
      },
      {
        path: "/hotels-listing",
        element: <HotelsListingPage />,
      },
      {
        path: "/rooms-listing/:id",
        element: <RoomsListingPage />,
      },
      {
        path: "/hotels-detailed/:id",
        element: <DetailedHotelPage />,
      },
      {
        path: "/room-detailed/:id",
        element: <DetailedRoomPage />,
      },
      {
        path: "/pixel-streaming/:id",
        element: <IntroPixelSteramingPage />,
      },
      {
        path: "/qrpay/:id",
        element: <QRPayment />,
      },
      {
        path: "/video-player/",
        element: <VideoPlayerPage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/sign-in",
        element: (
          <LoginLayout>
            <SignIn />
          </LoginLayout>
        ),
      },
      {
        path: "/sign-up",
        element: (
          <LoginLayout>
            <SignUp />
          </LoginLayout>
        ),
      },
      {
        path: "/password-recovery",
        element: (
          <LoginLayout>
            <PasswordRecovery />
          </LoginLayout>
        ),
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/favourites",
        element: <FavouritesPage />,
      },
      {
        path: "/info",
        element: <InfoPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <Error />,
  },
]);

export default router;
