import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useState, useRef } from "react";

const genderOptions = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Other", value: "Other" },
];

const SignUp = () => {
  const [activeInput, setActiveInput] = useState(0);

  const [activeGenderOptions, setActiveGenderOptions] = useState([]);
  const [selectedGender, setSelectedGender] = useState("Gender");
  const [focusedOptionIndex, setFocusedOptionIndex] = useState();
  const [genderSelectedFlag, setGenderSelectedFlag] = useState(false);
  const hiddenGenderInput = useRef(null);
  const handleGenderOptionsChange = () => {
    activeGenderOptions.length
      ? setActiveGenderOptions([])
      : setActiveGenderOptions(genderOptions);
  };
  return (
    <>
      <FocusableChild
        className="login-input-wrapper"
        borderAlways={true}
        onFocus={() => setActiveInput(0)}
        onArrowPress={(e) => {
          if (e === "up" || e === "right") {
            return false;
          }
          setActiveInput(null);
          return true;
        }}
      >
        <input
          type="text"
          name="fullName"
          placeholder="Full Name"
          className={`login-input ${
            activeInput === 0 ? "bg-primary-800" : "bg-transparent"
          }`}
        />
      </FocusableChild>

      <FocusableChild
        className="login-input-wrapper"
        onArrowPress={(e) => {
          if (e === "right") {
            return false;
          }
          if (e === "down") {
            setFocus("phonePrefixInput");
            return false;
          }
          setActiveInput(null);
          return true;
        }}
        borderAlways={true}
        onFocus={() => setActiveInput(1)}
      >
        <input
          type="text"
          name="email"
          placeholder="Mail"
          className={`login-input ${
            activeInput === 1 ? "bg-primary-800" : "bg-transparent"
          }`}
        />
      </FocusableChild>

      <div className="w-full flex items-center">
        <FocusableChild
          className="w-20 mb-10 mr-8 rounded-[8px]"
          focusKeyParam={"phonePrefixInput"}
          borderAlways={true}
          onFocus={() => setActiveInput(2)}
          onArrowPress={() => setActiveInput(null)}
        >
          <div className="w-full h-[72px] flex items-center pl-[10px] text-white rounded-[8px] bg-primary-800">
            <span>+</span>
            <input
              type="number"
              name="prefix"
              placeholder="....."
              min="1"
              max="9999"
              className={`bg-transparent w-full outline-none ${
                activeInput === 2 ? "bg-primary-800" : "bg-transparent"
              }`}
            />
          </div>
        </FocusableChild>
        <FocusableChild
          className="login-input-wrapper"
          borderAlways={true}
          onFocus={() => setActiveInput(3)}
          onArrowPress={(e) => {
            if (e === "right") {
              return false;
            }
            setActiveInput(null);
            return true;
          }}
        >
          <input
            type="number"
            name="phone"
            placeholder="Phone Number"
            className={`login-input ${
              activeInput === 3 ? "bg-primary-800" : "bg-transparent"
            }`}
          />
        </FocusableChild>
      </div>

      <FocusableChild
        className="login-input-wrapper"
        focusKeyParam={"genderInput"}
        onEnter={handleGenderOptionsChange}
        onArrowPress={(e) => {
          if (e === "right") {
            return false;
          }

          if (!activeGenderOptions.length) {
            return;
          }

          setActiveInput(null);

          if (e === "down") {
            setFocus(`genderItem${0}`);
            return false;
          }
          return true;
        }}
        borderAlways={true}
        onFocus={() => setActiveInput(4)}
      >
        <div
          className={`relative login-input ${
            activeInput === 4 ? "bg-primary-800" : "bg-transparent"
          }`}
        >
          <p
            className={`w-full h-full flex items-center justify-start text-white ${
              genderSelectedFlag ? "text-white" : "text-basicGray"
            }`}
          >
            {selectedGender}
          </p>
          {activeGenderOptions.length ? (
            <div className="absolute top-full left-0 mt-3 w-72 py-4 bg-primary-800 rounded-xl">
              {activeGenderOptions.map((option, i) => {
                return (
                  <FocusableChild
                    key={i}
                    className={`w-64 h-10 flex items-center justify-start mx-4 pl-4 rounded ${
                      i === focusedOptionIndex
                        ? "bg-selectionGray"
                        : "bg-transparent"
                    }`}
                    focusKeyParam={`genderItem${i}`}
                    onFocus={() => setFocusedOptionIndex(i)}
                    onEnter={() => {
                      setGenderSelectedFlag(true);
                      setSelectedGender(option.name);
                      setActiveGenderOptions([]);
                      setFocus("genderInput");

                      if (hiddenGenderInput.current) {
                        hiddenGenderInput.current.value = option.value;
                      }
                    }}
                    onArrowPress={(e) => {
                      if (e === "right" || e === "left") {
                        return false;
                      }

                      if (e === "up" && i === 0) {
                        console.log("i", i);
                        setFocus("genderInput");
                        setActiveGenderOptions([]);
                        setFocusedOptionIndex(null);
                        return false;
                      }
                      if (e === "down") {
                        if (activeGenderOptions.length - 1 === i) {
                          setFocus("signUpPasswordInput");
                          setActiveGenderOptions([]);
                          setFocusedOptionIndex(null);
                          return false;
                        }
                        setFocus(`genderItem${i + 1}`);
                        return false;
                      }
                      return true;
                    }}
                    borderHidden={true}
                  >
                    <div>{option.name}</div>
                  </FocusableChild>
                );
              })}
            </div>
          ) : null}
          <input
            type="text"
            name="gender"
            ref={hiddenGenderInput}
            className="hidden"
          />
        </div>
      </FocusableChild>

      <div className="w-full flex items-center">
        <FocusableChild
          focusKeyParam={"signUpPasswordInput"}
          className="w-1/2 mb-10 mr-8 rounded-[8px]"
          borderAlways={true}
          onFocus={() => setActiveInput(5)}
          onArrowPress={() => setActiveInput(null)}
        >
          <input
            type="password"
            name="password"
            placeholder="Password"
            className={`w-full h-[72px] pl-[10px] text-white rounded-[8px] ${
              activeInput === 5 ? "bg-primary-800" : "bg-transparent"
            }`}
          />
        </FocusableChild>
        <FocusableChild
          className="w-1/2 mb-10 mr-8 rounded-[8px]"
          borderAlways={true}
          onFocus={() => setActiveInput(6)}
          onArrowPress={(e) => {
            if (e === "right") {
              return false;
            }
            setActiveInput(null);
            return true;
          }}
        >
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className={`w-full h-[72px] pl-[10px] text-white rounded-[8px] ${
              activeInput === 6 ? "bg-primary-800" : "bg-transparent"
            }`}
          />
        </FocusableChild>
      </div>
    </>
  );
};

export default SignUp;
