export default function Home() {
  return (
    <svg
      className="min-w-[32px]"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.5 18.636V12.3607C20.5 11.855 20.3958 11.3546 20.1936 10.89C19.9915 10.4253 19.6957 10.0061 19.3242 9.65784L13.3014 4.01261C12.9504 3.6835 12.4847 3.5 12.0005 3.5C11.5163 3.5 11.0505 3.6835 10.6995 4.01261L4.67583 9.65784C4.30434 10.0061 4.00852 10.4253 3.80638 10.89C3.60424 11.3546 3.5 11.855 3.5 12.3607V18.636C3.5 19.1303 3.69901 19.6045 4.05324 19.954C4.40748 20.3036 4.88792 20.5 5.38889 20.5H18.6111C19.1121 20.5 19.5925 20.3036 19.9468 19.954C20.301 19.6045 20.5 19.1303 20.5 18.636Z"
        stroke="#ECECEC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
