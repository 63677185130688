import React, { Fragment, useState, FC, useEffect, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "./DatePickerCustomDay";
import DatePicker from "react-datepicker";
import ClearDataButton from "./ClearDataButton";
import FocusableChild from "./FocusableChild";
import {
	pause,
	resume,
	setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { useSearchParams } from "react-router-dom";

function formatDateForAriaLabel(date) {
	const daysOfWeek = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const dayOfWeek = daysOfWeek[date.getDay()];
	const month = months[date.getMonth()];
	const day = date.getDate();
	const suffix =
		day >= 11 && day <= 13 ? "th" : ["st", "nd", "rd"][(day % 10) - 1] || "th";

	return `Choose ${dayOfWeek}, ${month} ${day}${suffix}, ${date.getFullYear()}`;
}

const StayDatesRangeInput = ({
	className = "[ lg:nc-flex-2 ]",
	fieldClassName = "[ nc-hero-field-padding ]",
	ignoredDirection,
	windowsOnTop = false,
}) => {
	const [query] = useSearchParams();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [arrowsShown, setArrowsShown] = useState(false);
	const showArrows = () => {
		setArrowsShown(true);
	};

	useEffect(() => {
		if (query.get("endDate")) {
			setEndDate(new Date(query.get("endDate")));
		}
		if (query.get("startDate")) {
			setStartDate(new Date(query.get("startDate")));
		}
	}, []);
	const onChangeDate = (dates, close) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		if (end) {
			console.log("here");
			resume();
			setFocus("engine-guests");
			console.log(document.querySelector("[focuskey='engine-guests']"));
			document.querySelector("[focuskey='engine-guests']").firstChild.focus();
		}
	};
	const calendarRef = useRef(null);
	const datePickerRef = useRef(null);
	useEffect(() => {
		document.documentElement.classList.add("dark");
	}, []);

	const renderInput = () => {
		return (
			<>
				<div className="text-neutral-300 dark:text-neutral-400">
					<CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
				</div>
				<div className="flex-grow text-left">
					<span className="block xl:text-lg font-semibold">
						{startDate?.toLocaleDateString("en-US", {
							month: "short",
							day: "2-digit",
						}) || "Add dates"}
						{endDate
							? " - " +
								endDate?.toLocaleDateString("en-US", {
									month: "short",
									day: "2-digit",
								})
							: ""}
					</span>
					<span className="block mt-1 text-neutral-400 leading-none font-light">
						{"Check in - Check out"}
					</span>
				</div>
			</>
		);
	};

	return (
		<FocusableChild
			focusKeyParam={"engine-calendar"}
			outline={true}
			onArrowPress={(e) => {
				if (e == "right") {
					setFocus("engine-guests");
					return false;
				}
				if (ignoredDirection) {
					if (e === ignoredDirection) {
						return false;
					}
				}
				return true;
			}}
			direction={"right"}
			onEnter={() => {
				calendarRef.current.click();
				setTimeout(() => {
					if (datePickerRef.current) {
						datePickerRef.current.setOpen(true);
						const currentDateElement =
							datePickerRef.current.calendar.componentNode.querySelector(
								`[aria-label="${formatDateForAriaLabel(startDate)}"]`,
							);
						console.log(currentDateElement);
						if (currentDateElement) {
							currentDateElement.focus();
						}
					}
				}, 150);
				pause();
			}}
			ignoreClick
			className={`StayDatesRangeInput z-10 relative flex ${className} `}
		>
			<Popover
				className={`StayDatesRangeInput z-10 relative flex ${className} `}
			>
				{({ open, close }) => (
					<>
						<input
							name="startDate"
							value={startDate}
							style={{ visibility: "hidden", position: "absolute" }}
						/>
						<input
							name="endDate"
							value={endDate}
							style={{ visibility: "hidden", position: "absolute" }}
						/>
						<Popover.Button
							ref={calendarRef}
							className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
								open ? "nc-hero-field-focused" : ""
							}`}
						>
							{renderInput()}
						</Popover.Button>

						{open && (
							<div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-[#1F2937] dark:bg-[#1F2937]"></div>
						)}

						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel
								className={`absolute left-1/2 z-10 ${
									windowsOnTop ? "mb-3 bottom-full" : "mt-3 top-full"
								} w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0`}
							>
								<div
									onMouseEnter={showArrows}
									className="overflow-hidden  rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-[#1F2937] dark:bg-[#1F2937] p-8"
								>
									<DatePicker
										selected={startDate}
										onChange={(dates) => onChangeDate(dates, close)}
										startDate={startDate}
										endDate={endDate}
										ref={datePickerRef}
										selectsRange
										showPopperArrow={false}
										inline
										renderCustomHeader={(p) => (
											<DatePickerCustomHeaderTwoMonth
												{...p}
												arrowsShown={arrowsShown}
											/>
										)}
										renderDayContents={(day, date) => (
											<DatePickerCustomDay dayOfMonth={day} date={date} />
										)}
									/>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</FocusableChild>
	);
};

export default StayDatesRangeInput;
