const PersonBig = () => {
  return (
    <svg
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 13.334C37 19.9614 31.6275 25.334 25 25.334C18.3726 25.334 13 19.9614 13 13.334C13 6.70657 18.3726 1.33398 25 1.33398C31.6275 1.33398 37 6.70657 37 13.334Z"
        stroke="#E9EDFD"
        stroke-width="2"
      />
      <path
        d="M10.5402 37.285C6.76747 39.5314 -3.12437 44.1183 2.90044 49.8583C5.8435 52.6621 9.12131 54.6674 13.2423 54.6674H36.7576C40.8787 54.6674 44.1565 52.6621 47.0995 49.8583C53.1243 44.1183 43.2325 39.5314 39.4597 37.285C30.6128 32.017 19.3872 32.017 10.5402 37.285Z"
        stroke="#E9EDFD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PersonBig;
