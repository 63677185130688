import {
  FocusContext,
  setFocus,
  useFocusable,
  resume
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useEffect } from "react";

const Modal = ({
  modalOpen,
  modalClose,
  message,
  secondButtonTitle,
  secondButtonClose,
}) => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: true,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  useEffect(() => {
    if(modalOpen) {
      focusSelf()
      resume()
    }
  }, [modalOpen])

  return (
    <>
    {modalOpen && <>
    <FocusContext.Provider value={focusKey}>
      <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center z-50">
        <div className="w-[600px] h-fit p-12 flex flex-col items-center justify-center bg-primary-600 rounded-2xl border border-1 border-white">
          <p className="text-2xl text-center capitalize">{message}</p>
      

          <div className="flex mt-12">
            {secondButtonTitle || secondButtonClose ? (
              <FocusableChild
                focusKeyParam={"modalSecondButton"}
                onEnter={secondButtonClose}
                className="bg-primary-800 rounded-[36px] px-6 py-4 border border-1 border-white"
                onArrowPress={(e) => {
                  if (e === "right") {
                    setFocus("modalCloseButton");
                    return false;
                  }
                  return false;
                }}
              >
                <button>{secondButtonTitle}</button>
              </FocusableChild>
            ) : null}

            <FocusableChild
              focusKeyParam={"modalCloseButton"}
              onEnter={modalClose}
              onArrowPress={(e) => {
                if (e === "left" && secondButtonClose) {
                  setFocus("modalSecondButton");
                  return false;
                }
                return false;
              }}
              className={`bg-primary-800 rounded-[36px] px-6 py-4 border border-1 border-white ${
                secondButtonClose ? "ml-8" : null
              }`}
            >
              <button>Cancel</button>
            </FocusableChild>
          </div>
        </div>
      </div>
    </FocusContext.Provider>
    
    </> }
    </>
  );
};

export default Modal;
