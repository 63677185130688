import { Link } from "react-router-dom";
import FocusableChild from "../components/FocusableChild";
import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const loginNavScheme = [
  {
    link: "/sign-in",
    text: "Enter Into The NOMADVERSE",
  },
  {
    link: "/sign-up",
    text: "Create New Account",
  },
  {
    link: "/",
    text: "CONTINUE AS A GUEST",
  },
];

const Login = () => {
  const { ref, focusKey, focused, focusSelf, hasFocusedChild } = useFocusable({
    focusKey: "home",
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    forceFocus: true,
    preferredChildFocusKey: null,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: "bar" },
  });

  const [activeChild, setActiveChild] = useState(0);

  useEffect(() => {
    focusSelf();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="relative w-full h-screen flex flex-col items-center justify-center">
      <p className="absolute left-32 top-8 text-2xl">NOMADVERSE</p>

      <FocusContext.Provider value={focusKey}>
        <div className="relative login-navigation">
          <h2 className="absolute whitespace-nowrap -top-20 left-1/2 -translate-x-1/2 text-start text-5xl text-white font-normal pb-0">
            Welcome, Please choose :
          </h2>
          {loginNavScheme.map((item, i) => {
            return (
              <Link to={item.link}>
                <FocusableChild
                  outline={true}
                  onFocus={() => setActiveChild(i)}
                  onEnter={() => navigate(item.link)}
                  borderAlways={true}
                  onArrowPress={(e) => {
                    if (e === "right") {
                      return false;
                    }

                    if (e === "up" && i === 0) {
                      return false;
                    }

                    if (e === "down" && i === loginNavScheme.length - 1) {
                      return false;
                    }

                    return true;
                  }}
                  className={`login-nav-button-wrapper ${
                    i === activeChild ? "bg-primary-800" : "bg-primary-600"
                  }`}
                >
                  <button className="login-nav-button">{item.text}</button>
                </FocusableChild>
              </Link>
            );
          })}
        </div>
      </FocusContext.Provider>
    </div>
  );
};

export default Login;
