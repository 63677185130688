const PlainArrow = () => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32166 1.56693L8.78832 8.50026L1.32166 15.4336"
        stroke="#E3E3E3"
        stroke-width="1.6"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default PlainArrow;
